import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../utils/config';
// components
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  // const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'xs');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);


  const theme = createTheme({
    palette: {
      primary: {
        main: '#1D313F',
      },
    },
  });

  const renderContent = (
    <Scrollbar 
      sx={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', mb:0,pb:20 },
      }}
    >
      {/* <Stack
        spacing={0}
        sx={{
          pt: 2,
          pb: 2,
          px: 2.5,
          mb:'15px',
          marginBottom: '0',
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }), justifyContent:"space-between"
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box style={{ height: "60px", width: "140px" }} ></Box>
        </Stack>
      </Stack> */}

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse}  className="sideBar-menus"/>

      {/* <Box sx={{ flexGrow: 1 }} /> */}

      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  );

  return (
    <ThemeProvider theme={theme}>
      <RootStyle className="sideBar"
        sx={{
          width: '128px',
          ...(collapseClick && {
            position: 'absolute',          
          }),
        }}
      >
        {!isDesktop && (
          <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
            {renderContent}
          </Drawer>
        )}

        {isDesktop && (
          <Drawer 
            open
            variant="persistent"
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            PaperProps={{
              className: "sideBar-Drawer",
              sx: {
                width: '128px',
                borderRightStyle: 'none',
                bgcolor: '#D9D9D9',
                transition: (theme) =>
                  theme.transitions.create('width', {
                    duration: theme.transitions.duration.standard,
                  }),
                ...(isCollapse && {
                  width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                }),
                ...(collapseHover && {
                  ...cssStyles(theme).bgBlur(),
                  boxShadow: (theme) => theme.customShadows.z24,
                }),
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </RootStyle>
    </ThemeProvider>
  );
}
