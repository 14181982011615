import * as Yup from "yup";
import { useCallback, useState, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, useParams } from "react-router-dom";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedTextFieldProps,
  Tab,
  Tabs,
  Box,
  IconButton,
  MenuItem,
  Grid,
  RadioGroup,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";

import PhotoCamera from "@mui/icons-material/PhotoCamera";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
import { fData } from "../../utils/formatNumber";
import axios from "../../utils/axiosconfig";
// components
import {
  RHFSwitch,
  RHFEditor,
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
  RHFRadioGroup,
} from "../../components/hook-form";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Scrollbar from "../../components/Scrollbar";
import Label from "../../components/Label";
import Group from "../../images/Group.svg";
import Plus from "../../images/Plus.svg";
import SearchNotFound from "../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { spacing } from "@mui/system";
import { RadioButtonChecked } from "@mui/icons-material";
import { MainContext } from "src/contexts/context";
import useSettings from "../../hooks/useSettings";

// import RadioGroup from '@mui/material';

//...................................
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// tab end

function Innerpage(dataaa) {
  const { children, c_Data, index, ...other } = dataaa;

  return (
    <div
      role="Innerpage"
      hidden={c_Data !== index}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      {...other}
    >
      {c_Data === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function abc(index) {
  return {
    id: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Group Name", alignRight: false },
  { id: "company", label: "Formula", alignRight: false },
  { id: "role", label: "Auto", alignRight: false },
  // { id: 'isVerified', label: 'Total Orders', alignRight: true },
  // { id: 'status', label: 'Status', alignRight: false },
];

//...................................
export default function NewCampaign() {
  const { themeStretch } = useSettings();

  const [groupList, setGroupList] = useState([]);
  const [iconimage, setIconImage] = useState();
  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [groupId, setGroupId] = useState("");
  const [value, setValue] = useState(0);
  const [deleted, setDeleted] = useState("");
  const [clicked, setClicked] = useState(false);
  const [active, setActive] = useState();

  const [filterValue, setFilterValue] = useState();
  const [searchValue, setSearchValue] = useState();
  const [searchPayload, setSearchPayload] = useState();
  const [filterData, setFilterData] = useState();
  const [limit, setLimit] = useState(0);
  const [type, setType] = useState("all");
  const [notificationId, setNotificationId] = useState();
  const { managerId } = useContext(MainContext);
  const navigate = useNavigate();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#e65100",
      },
    },
  });
  console.log(groupId, notificationId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tableClick = (id) => {
    setClicked(!clicked);
    if (clicked === true) {
      setGroupId(id);
    }
    console.log(groupId);
  };

  const [c_Data, setc_Data] = useState(0);

  const pageChange = (event, newc_Data) => {
    setc_Data(newc_Data);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = groupList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id) => {
    console.log(id);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (userId) => {
    const deleteUser = groupList.filter((user) => user.id !== userId);
    setSelected([]);
    setGroupList(deleteUser);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = groupList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setGroupList(deleteUsers);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groupList.length) : 0;

  const filteredUsers = applySortFilter(
    groupList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && Boolean(filterName);

  const getNotification = async () => {
    const payload = {};
    payload.managerId = managerId;
    payload.type = type;
    payload.filter = filterValue;
    payload.search = searchPayload;
    payload.page = page;
    payload.limit = limit;
    try {
      const res = await axios.post(`/notifications/get/all`, payload);
      setNotificationList(res.data.result);
    } catch (e) {
      console.log(e);
    }
  };

  const getGroup = async () => {
    const payload = {};
    payload.managerId = managerId;
    payload.type = type;
    payload.filter = filterValue;
    payload.search = searchPayload;
    payload.page = page;
    payload.limit = limit;
    try {
      const res = await axios.post(`/groups/get/all`, payload);
      setGroupList(res.data.result);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getNotification();
    getGroup();
  }, []);

  const getGroupId = (id) => {
    setGroupId(id);
    console.log(groupId);
  };

  // API for create campaign
  const addCampaign = () => {
    axios
      .post("/campaigns/add", {
        groupId: groupId,
        notificationId: notificationId,
        managerId: managerId,
      })
      .then(() => {
        navigate(PATH_DASHBOARD.notification.notifications);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ThemeProvider>
      <Container
        maxWidth={themeStretch ? false : "xl"}
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
      >
        <FormProvider>
          {/* <Typography sx={{textDecoration:"underline #e65100",fontSize:"32PX",fontWeight:"300",borderBottom:"1px solid green"}}>CREATE NEW CAMPAIGN</Typography> */}
          <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="CREATE NEW CAMPAIGN"
                {...a11yProps(0)}
                style={{
                  color: "#030303",
                  fontSize: "32px",
                  fontWeight: "300",
                  maxWidth: "380px",
                }}
              />
            </Tabs>
          </Box>
          <HeaderBreadcrumbs
            heading={""}
            links={[
              {
                name: "Campaigns",
                href: PATH_DASHBOARD.notification.notifications,
              },
              {
                name: "Add new",
                href: PATH_DASHBOARD.notification.root,
              },
            ]}
            action={
              <Button variant="contained" onClick={() => addCampaign()}>
                ADD
              </Button>
            }
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Grid item xs={12} md={5} lg={5} sx={{ width: "30%" }}>
              <Card sx={{ padding: "10px 30px" }}>
                <Typography
                  sx={{
                    marginBottom: "15px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Notification
                </Typography>{" "}
                {notificationList.map((row) => {
                  return (
                    <NewCard
                      row={row}
                      key={row.id}
                      sx={{ marginBottom: "5px" }}
                      onClick={() => setActive(row)}
                      setNotificationId={setNotificationId}
                    />
                  );
                })}
              </Card>
            </Grid>
            <Grid sx={{ width: "65%" }}>
              <Card>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    background: "#f4f6f8",
                    borderRadius: "10px 10px 0 0",
                    padding: "10px 10px 0 10px",
                  }}
                >
                  <Tabs
                    value={c_Data}
                    onChange={pageChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Groups" {...abc(0)} />
                    {/* <Tab label="Active" {...abc(1)} />
                                    <Tab label="Banned" {...abc(2)} /> */}
                  </Tabs>
                </Box>
                <Innerpage value={c_Data} index={0}></Innerpage>
                <Innerpage value={c_Data} index={1}>
                  Item Two
                </Innerpage>
                <Innerpage value={c_Data} index={2}>
                  Item Three
                </Innerpage>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  onDeleteUsers={() => handleDeleteMultiUser(selected)}
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableCell></TableCell>
                        <TableCell>Group Name </TableCell>
                        <TableCell>formula</TableCell>
                        <TableCell>Auto</TableCell>
                      </TableHead>
                      <TableBody>
                        {filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                            const isItemSelected =
                              selected.indexOf(row.id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                                // sx={{background:clicked ? "#ef8268":""}}
                                // onClick={()=>tableClick(row.id)}
                              >
                                <TableCell padding="checkbox">
                                  <label class="radio-checkbox">
                                    <input
                                      type="radio"
                                      name="option"
                                      onClick={() => setGroupId(row.id)}
                                    />
                                    <span class="checkmark_group"></span>
                                  </label>
                                </TableCell>
                                <TableCell
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  {/* <Avatar alt={row.name} src={row.avatar} sx={{ mr: 2 }} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {row.groupName}
                                  </Typography>
                                </TableCell>
                                {row.formula.map((item) => (
                                  <TableCell>
                                    {item.field} {item.operator}{" "}
                                    {item.value === false ? "false" : "true"}
                                  </TableCell>
                                ))}

                                <TableCell align="left">
                                  <Label
                                    color={
                                      row.isAuto === false ? "error" : "success"
                                    }
                                  >
                                    {row.isAuto ? "Yes" : "No"}
                                  </Label>
                                </TableCell>

                                {/* <TableCell align="right">
                              <ThreedotMenu row={row} deleteGroup={deleteGroup} />
                            </TableCell> */}
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={groupList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, page) => setPage(page)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
            {/* <LoadingButton sx={{ padding: "15px 0" }} type="submit" fullWidth variant="contained" size="large" loading={isSubmitting}>
                        {"ADD"}
                    </LoadingButton> */}
          </Box>
        </FormProvider>
      </Container>
    </ThemeProvider>
  );

  const handleSubmit = () => {
    return;
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter(
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
// /Side notification Bar
export function NewCard({ row, setNotificationId }) {
  return (
    <label class="container">
      <input
        type="radio"
        name="radio"
        onClick={() => setNotificationId(row.id)}
      />
      <span class="checkmark">
        <Box
          style={{
            marginRight: "15px",
            height: "70px",
            width: "70px",
            borderRadius: "35px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={row.icon} alt="kjhg" className="img_container" />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#020202",
              fontSize: "16px",
              fontWeight: 700,
              marginBottom: "10px",
            }}
          >
            {row.title}
          </Typography>
          <Typography
            sx={{ color: "#020202", fontSize: "16px", fontWeight: 300 }}
          >
            {row.message}
          </Typography>
        </Box>
      </span>
    </label>
  );
}
