import * as Yup from 'yup';
import { useCallback, useEffect, useState,useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Grid, Card, Chip, Container, Stack, Button, TextField, Typography, Autocomplete, Box,IconButton,Switch } from '@mui/material';
// routes
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from '../../utils/axiosconfig';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// components
import { RHFSwitch, RHFEditor, FormProvider, RHFTextField, RHFUploadSingleFile, RHFRadioGroup, RHFSelect } from '../../components/hook-form';
import coupon from '../../images/coupon.png';
import useSettings from '../../hooks/useSettings';
import { MainContext } from 'src/contexts/context';
// import CouponList from './couponList';




const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }));



export default function NewPackage() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
 
  const { id } = useParams();
 
  
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
 const [cityList,setCityList] = useState([]);
  const [type, setType] = useState("");
  const[city,setCity] = useState('');
  const [isActive,setIsActive] = useState(false);
  const [fragile,setFragile] = useState(false);
  const [cityId,setCityId ]= useState('');
  const [packageList,setPackageList] = useState([]);
  const [icon, setIcon] = useState(null);

  const GENDER_OPTION = ['false', 'true'];
  const theme = createTheme({
    palette: {
      primary: {
        main: '#e65100',
      },
    },
  });
  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });


useEffect(()=>{
  if(packageList){
  getCoupon();}
},[])

  const types = [
    {
      id: 1, label: "flat"
    },
    {
      id: 2, label: "percent"
    }
  ]




  const {
   
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const getCoupon =  () => {
    axios.get(`/packageTypes/get`, {
        // config
      }).then((res) => {
        console.log(res.data);
      setPackageList(res.data.result);
      const currentPackage = packageList.find((item)=>item.id === id);
      setIsActive(currentPackage?.isActive);
      setFragile(currentPackage?.fragile);
      setCityId(currentPackage?.cityId);
      setType(currentPackage?.type);
      setIcon(currentPackage?.icon);
     
    console.log("resultttt",res.data.result);
    }).catch((err) => {
      console.log("error", err);
    })
  }


  const onSubmit = () => {
    if (!isEdit) {
        let formData = new FormData();
        formData.append('icon', icon);
        formData.append('cityId', cityId);
        formData.append('type', type);
       
        axios.post('/packageTypes/add', formData).then((res) => {
          console.log(res);
         
          navigate(PATH_DASHBOARD.pacakges.pacakges);
        }).catch((err) => {
          console.log(err);
        })
      } 
      
      else{
  
        let formData = new FormData();
        formData.append('icon', icon);
        formData.append('type', type);
        axios.put(`/packageTypes/update/${id}`, formData).then((res) => {
          console.log(res);
          navigate(PATH_DASHBOARD.pacakges.pacakges);
        }).catch((err) => {
          console.log(err);
        })
  
      }
}


  useEffect(() => {
    getCity();
  }, []);

 


  const handleFileChange = (event) => {
    setIcon(event.target.files[0]);
  };

  const getCity = () => {
    axios.get("cities/get").then((res) => {
      console.log(res.data);
      //  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
      setCityList(res.data.result);
    }).catch((err) => {
      console.log("error", err)
    })
  }





  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
          <Box
              sx={{
                display: 'grid',
                columnGap: 8,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="name" label="Pacakge Name" value={type} onChange={(e) => setType(e.target.value)} required />
              <RHFSelect name="city" label="City" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} required>

                <option value="" />
                {cityList.map((option) => (
                  <option key={option.city} value={option.city}>
                    {option.city}
                  </option>
                ))}
              </RHFSelect>
              <RHFTextField name="name" label="City id" value={cityId} onChange={(e) => setCityId(e.target.value)} required />
              <div>
                <LabelStyle>Icons</LabelStyle>
                <IconButton variant="contained" component="label" color="primary">

                  <input hidden accept="image/*" type="file"  onChange={handleFileChange} required />
                  <PhotoCamera fontSize='large' />
                </IconButton>
              </div>
              <div>
              <LabelStyle>Fragile</LabelStyle>
              <Switch name="x" label="Fragile" checked={fragile} onChange={(e) => setFragile(e.target.checked)} required />
              </div>
              <Stack spacing={3} mt={2}>
                <div>
                  <LabelStyle>Active</LabelStyle>
                 
                  <Switch name="y" label="Active" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} required />
                </div>

              </Stack>

            </Box>

            <Stack spacing={3} alignItems="flex-end">

              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                {!isEdit ? 'Create Package' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}