import { useState } from "react";
import axios from "../../utils/axiosconfig";
import {
  Avatar,
  Typography,
  Box,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import c_circle from "../../images/call-circle.svg";
import m_circle from "../../images/message-circle.svg";
import bill from "../../images/bill.png";
import larrow from "../../images/arrow-left.svg";
import invoice from "../../images/invoiceIcon.svg";
import Status from "../../images/Vectors.png";
import Three_Dots from "../../images/Three_Dots.svg";
import { useSnackbar } from "notistack";
import Conformation from "../Conformation";
const BookingDetailsTab = (props) => {
  const style = {
    heading: {
      color: "#E3552E",
      fontSize: "10px",
      fontWeight: "700",
    },
    textCustName: {
      color: "#002236",
      fontSize: "14px",
      fontWeight: "500",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    instructioncontainer: {
      backgroundColor: "#F9F9F9",
      // borderRadius: "20px",
      borderRadius: "1.04vw",
      // padding: "15px 20px",
      padding: "0.7vw 1.04vw",
      marginTop: "11px",
      marginRight: "5px",
      alignItems: "center",
    },
  };

  const generateMapLink = (pickuplat, pickuplong) => {
    console.log("coords", pickuplat, pickuplong);
    const baseUrl = "https://www.google.com/maps"; // Google Maps URL
    const query = `q=${pickuplat},${pickuplong}`;
    return `${baseUrl}?${query}`;
  };

  const hidePayment = () => setShow(true);
  const [show, setShow] = useState(true);
  const [openConf, setOpenConf] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const Convert = (dt) => {
    let m = new Date(dt).getHours() || "";
    let n = m >= 12 ? "PM" || "" : "AM" || "";
    const mins = new Date(dt).getMinutes() || "";
    const zero = mins < 10 ? "0" : "" || "";
    const h = m > 12 ? m - 12 : m || "";
    const dd = `${h}:${zero}${mins} ${n}` || "";
    return dd || "";
  };
  const ConvertDt = (dt) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = new Date(dt).getDate() || "";
    const monthIndex = new Date(dt).getMonth() || "";
    const month = months[monthIndex] || "";
    const year = new Date(dt).getFullYear() || "";

    let suffix = "th" || " ";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st" || " ";
    } else if (day === 2 || day === 22) {
      suffix = "nd" || " ";
    } else if (day === 3 || day === 23) {
      suffix = "rd" || " ";
    }

    return day + suffix + " " + month || " ";
  };
  const openPay = () => {
    setShow(false);
  };
  /* Booking Details copy cancel functionality */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    userName,
    userAvatar,
    bookingNumber,
    userMobile,
    totaldistance,
    packageType,
    bookingstageobj,
    userObjFromBooking,
    creatingTime,
    distance,
    couponId,
    TotalFeeDisc,
    TotalFeeGst,
    TotalDiscount,
    scheduleTime,
    currentDate,
    yesterday,
    tomorrow,
    userMessage,
    amount,
    activeId,
    landMark,
    desAddress,
    desMobile,
    desName,
    latitude,
    longitude,
    latitude1,
    longitude1,
    landMark1,
    desAddress1,
    desMobile1,
    desName1,
  } = props;

  const handleCopyLink = () => {
    console.log("test91...........", desMobile, desMobile1);
    const bookingno = `${bookingNumber}`;
    const user = `${userName}`;
    const scheduletime = `⏰ ${ConvertDt(scheduleTime)},${Convert(
      scheduleTime
    )}`;
    const pickupAddress = `*Pickup* - ${desName},${
      landMark?.replace(
        /(\w)(\w*)/g,
        (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
      ) || ""
        ? landMark
        : "no landmark"
    },  ${desAddress}`;
    const pickupPhone = `☎️ ${desMobile}`;
    const dropAddress = ` *Drop* - ${desName1},${
      landMark1?.replace(
        /(\w)(\w*)/g,
        (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
      ) || ""
        ? landMark1
        : "no landmark"
    },  ${desAddress1}`;
    const dropPhone = `☎️ ${desMobile1}`;
    const mapLinkPickupDestination = generateMapLink(latitude, longitude);
    const mapLinkDropDestination = generateMapLink(latitude1, longitude1);
    const bookingInstrcutions = `❗${userMessage}`;
    const formattedText = `*${bookingno} - ${user} ${scheduletime}*
${bookingInstrcutions}

${pickupAddress}
${pickupPhone}
📍${mapLinkPickupDestination}

${dropAddress}
${dropPhone}
📍${mapLinkDropDestination}
   `;
    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        enqueueSnackbar("Copied to clipboard.", {
          style: { marginTop: "100px" },
        });
        console.log("Link copied to clipboard:", formattedText);
      })
      .catch((error) => {
        console.log("Failed to copy link:", error);
      });
    handleClose();
  };
  
const copyMobileNo=(mobileNo)=>{
  navigator.clipboard
  .writeText(mobileNo)
  .then(() => {
    enqueueSnackbar("Copied to clipboard.", {
      style: { marginTop: "100px" },
    });
  })
  .catch((error) => {
    console.log("Failed to copy link:", error);
  });
}
  const statusChip = () => {
    if (bookingstageobj) {
      return (
        <Box
          sx={{
            backgroundColor: "#75CEF8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "7px",
            padding: "7px",
            marginBottom: "4px",
          }}
        >
          <Box>
            <img src={Status} alt="" />
          </Box>

          <Box>
            <Typography
              style={{
                color: "black",
                fontWeight: "700",
                fontSize: "0.83vw",
              }}
            >
              {bookingstageobj}
            </Typography>
          </Box>
        </Box>
      );
    } else {
      return (
        <>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="5vw"
            height="4vh"
            sx={{
              marginBottom: "5px",
            }}
          />
        </>
      );
    }
  };
  const timeDetail = () => {
    if (scheduleTime) {
      return (
        <strong>
          {Convert(scheduleTime) || ""},
          {ConvertDt(scheduleTime) === ConvertDt(currentDate)
            ? "Today"
            : ConvertDt(scheduleTime) === ConvertDt(tomorrow)
            ? "Tomorrow"
            : ConvertDt(scheduleTime) === ConvertDt(yesterday)
            ? "Yesterday"
            : ConvertDt(scheduleTime) || ""}
        </strong>
      );
    } else {
      return (
        <>
          <Box
            sx={{
              width: "8vw",
            }}
          >
            <Typography variant="body1">
              <Skeleton variant="text" sx={{ fontSize: "0.83vw" }} />
            </Typography>
          </Box>
        </>
      );
    }
  };
  const cancelBooking = async () => {
    handleClose();
    setOpenConf(false);
    if (activeId != null) {
      await axios
        .post(`/bookings/cancel-booking/${activeId}`)
        .then((res) => {
          console.log("booking Cancelled");
        })
        .catch((err) => {
          console.log("booking data fetch error", err);
        });
    }
  };

  const getUserInfo = () => {
    if (userObjFromBooking) {
      return (
        <Box>
          <Conformation
            open={openConf}
            onClose={() => {
              setOpenConf(false);
              handleClose();
            }}
            handleSubmit={cancelBooking}
            title={`Cancel Booking Confirmation`}
            subTitle={`Do you want to cancel booking?`}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.2vw",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Avatar
                alt={userName}
                src={userAvatar}
                style={{
                  height: "2.86vw",
                  width: "2.86vw",
                  marginRight: "9px",
                }}
              />
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  style={{
                    fontSize: "0.7291vw",
                    fontWeight: 500,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {userName?.replace(
                    /(\w)(\w*)/g,
                    (_, g1, g2) => g1?.toUpperCase() + g2?.toLowerCase()
                  ) || ""}
                </Typography>
                <Typography
                  style={{
                    fontSize: "0.7291vw",
                    fontWeight: 600,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {bookingNumber || ""}
                </Typography>
                <Typography
                  style={{
                    fontSize: "0.625vw",
                    fontWeight: 600,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {userMobile || ""}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "0.625vw",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  }}
                >
                  {ConvertDt(creatingTime) || 0} | &nbsp;
                  {Convert(creatingTime) || 0}
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", gap: "5px", marginLeft: "auto" }}>
              <Box style={{ paddingTop: "12px", fontSize: "14px" }}>
                <Typography sx={{ fontSize: "0.83vw" }}>
                  <strong>{totaldistance || ""} km.</strong>
                </Typography>
              </Box>
              <Box
                style={{
                  height: "2.08vw",
                  width: "2.08vw",
                  borderRadius: "50%",
                  background: "#E3552E",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => openPay()}
              >
                <img
                  src={bill}
                  alt=""
                  style={{ position: "absolute", height: "60%", width: "60%" }}
                />
              </Box>
              <Box
              onClick={()=>copyMobileNo(userMobile)}
                style={{
                  height: "2.08vw",
                  width: "2.08vw",
                  borderRadius: "50%",
                  background: "#E3552E",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={m_circle}
                  alt=""
                  style={{ position: "absolute", height: "50%", width: "50%" }}
                />
              </Box>
              <Box
                style={{
                  height: "2.08vw",
                  width: "2.08vw",
                  borderRadius: "50%",
                  background: "#E3552E",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={c_circle}
                  alt=""
                  style={{ position: "absolute", height: "50%", width: "50%" }}
                />
              </Box>
            </Box>
          </Box>
          {packageType ? (
            <Typography
              style={{
                color: "#141414",
                fontSize: "0.67vw",
                fontWeight: "400",
                marginLeft: "3.125vw",
              }}
            >
              PACKAGE TYPE :{" "}
              <span
                style={{
                  color: "#141414",
                  fontSize: "0.67vw",
                  fontWeight: "700",
                }}
              >
                {packageType?.replace(
                  /(\w)(\w*)/g,
                  (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                ) || ""}
              </span>
            </Typography>
          ) : (
            <Typography
              style={{
                color: "#141414",
                fontSize: "0.67vw",
                fontWeight: "400",
                marginLeft: "3.125vw",
              }}
            >
              PACKAGE TYPE :{" "}
              <span
                style={{
                  color: "#141414",
                  fontSize: "0.78125vw",
                  fontWeight: "700",
                }}
              >
                Default
              </span>
            </Typography>
          )}
          <Typography
            style={{
              color: "#141414",
              fontSize: "0.67vw",
              fontWeight: "400",
              marginLeft: "3.125vw",
            }}
          >
            CURRENT STATUS :
            <span
              style={{
                color: "#141414",
                fontSize: "0.67vw",
                fontWeight: "700",
              }}
            >
              {" "}
              {bookingstageobj === "Drop"
                ? "Pickup is completed"
                : bookingstageobj === "Completed"
                ? "Package is delivered"
                : bookingstageobj === "Pending"
                ? "Pickup and drop has not started yet"
                : bookingstageobj === "Booked"
                ? "Pickup is going to start"
                : bookingstageobj === "Cancelled"
                ? "Booking is cancelled"
                : " "}
            </span>
          </Typography>
          <Box
            style={style.instructioncontainer}
            sx={{ overflowY: "scroll", height: "6vh" }}
          >
            <Typography
              sx={{
                color: "#E3552E",
                fontSize: "0.625vw",
                fontWeight: "700",
              }}
            >
              Instructions
            </Typography>
            <Box style={style.box}>
              <Typography
                sx={{
                  fontSize: "0.7291vw",
                  fontWeight: 700,
                  color: "#9D9E9E",
                  fontFamily: "Cabin",
                }}
              >
                {userMessage?.replace(
                  /(\w)(\w*)/g,
                  (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                ) || ""
                  ? userMessage
                  : "No Instrcutions for this booking "}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "20px",
            }}
          >
            <Skeleton
              variant="circular"
              animation="wave"
              width={60}
              height={60}
              sx={{ marginRight: "15px" }}
            />
            <Box
              sx={{
                flexDirection: "column",
                width: "40%",
              }}
            >
              <Typography variant="body1">
                <Skeleton
                  variant="text"
                  width="40%"
                  sx={{ fontSize: "0.83vw" }}
                />
              </Typography>
              <Typography variant="body2">
                <Skeleton
                  variant="text"
                  width="45%"
                  sx={{ fontSize: "0.83vw" }}
                />
              </Typography>
              <Typography variant="body1">
                <Skeleton
                  variant="text"
                  width="70%"
                  sx={{ fontSize: "0.83vw" }}
                />
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                gap: "5px",
              }}
            >
              <Skeleton
                variant="circular"
                animation="wave"
                width="2.08vw"
                height="2.08vw"
              />
              <Skeleton
                variant="circular"
                animation="wave"
                width="2.08vw"
                height="2.08vw"
              />
              <Skeleton
                variant="circular"
                animation="wave"
                width="2.08vw"
                height="2.08vw"
              />
              {/* <Typography variant="body1">
                  <Skeleton variant="text" width="100%" />
                </Typography>
                <Typography variant="body2">
                  <Skeleton variant="text" width="100%" />
                </Typography> */}
            </Box>
          </Box>

          <Box sx={{}}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="100%"
              height="7vh"
              sx={{
                borderRadius: "10px",
              }}
            />
          </Box>
        </Box>
      );
    }
  };
  return (
    <>
      <Box sx={{ background: "white", height: "29vh" }}>
        {!show ? (
          <>
            <Box sx={{ zIndex: "-2", padding: "20px 8px 4px" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #E04E2C",
                }}
              >
                <img
                  src={larrow}
                  onClick={hidePayment}
                  height={20}
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />

                <Typography
                  style={{
                    color: "#030303",
                    fontWeight: 400,
                    background: "white",
                    fontSize: "0.9375vw",
                    paddingBottom: "3px",
                  }}
                >
                  INVOICE DETAILS
                </Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex", padding: "4px" }}>
                  <img src={invoice} alt="" style={{ paddingTop: "5px" }} />
                  <Typography
                    className="locationtitle"
                    style={{ paddingTop: "7px" }}
                  >
                    To Pay
                  </Typography>
                </Box>
                <Typography style={{ fontWeight: "800", padding: "5px" }}>
                  ₹{amount}
                </Typography>
              </Box>

              <Typography
                className="paymentdetails"
                style={{ padding: "5px", fontSize: "0.9375vw" }}
              >
                Payment Details
              </Typography>

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "6px",
                }}
              >
                <div className="bill">
                  <Typography
                    className="billcost"
                    sx={{ fontSize: "0.9375vw" }}
                  >
                    Delivery, {distance} km
                  </Typography>
                </div>
                <Typography
                  style={{
                    flex: "1",
                    borderBottom: "2px dashed #ccc",
                    margin: "0 10px",
                  }}
                ></Typography>
                {couponId ? (
                  <Typography style={{ fontWeight: "800" }}>
                    ₹{amount}
                  </Typography>
                ) : (
                  <Typography style={{ fontWeight: "800" }}>
                    ₹{amount}
                  </Typography>
                )}
              </Box>
              {couponId ? (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="bill">
                    <Typography className="billcost">Applied coupon</Typography>
                  </div>
                  <Typography
                    style={{
                      flex: "1",
                      borderBottom: "2px dashed #ccc",
                      margin: "0 10px",
                    }}
                  ></Typography>
                  <Typography style={{ fontWeight: "700" }}>
                    {couponId} ₹{TotalDiscount}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </>
        ) : (
          <>
            {" "}
            <Box
              sx={{
                padding: "0.625vw",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid rgb(227, 85, 46)",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter,sans-serif",
                    fontWeight: 300,
                    color: "#030303",
                    fontSize: "0.9375vw",
                  }}
                >
                  BOOKING DETAILS
                </Typography>

                {statusChip()}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      style={{ fontSize: "0.7291vw", fontWeight: "700" }}
                    >
                      {timeDetail()}
                    </Typography>
                  </Box>
                  <Box>
                    <Box>
                      <img
                        src={Three_Dots}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickEvent}
                      />

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => handleCopyLink()}>
                          Copy
                        </MenuItem>
                        <MenuItem onClick={() => setOpenConf(true)}>
                          Cancel
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  // padding: "24px 26px",
                  // boxShadow: "0 0 6px 0px #e6e6e6",
                  // position: "relative",
                  // background: "white",
                  marginTop: "0.5vw",
                  // boxShadow:
                  //   "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                  // minHeight: "240px",
                  // maxHeight: "240px",
                  // minHeight: "12.5vw",
                }}
              >
                {getUserInfo()}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default BookingDetailsTab;
