import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axiosconfig";
export const fetchBusinessBookings = createAsyncThunk(
  "getBusinessBookings",
  async (payload) => {
    const { businessId } = payload;
    payload.cityId = localStorage.getItem("manager_city");
    const response = await axios.post(
      `/bookings/get/all/${businessId}`,
      payload
    );
    return response;
  }
);
const getBusinessBookings = createSlice({
  name: "getBusinessBookings",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = [];
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessBookings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBusinessBookings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchBusinessBookings.rejected, (state, action) => {
      console.log("Error............", action.payload);
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const { reset } = getBusinessBookings.actions;
export default getBusinessBookings.reducer;
