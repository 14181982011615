
// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';



// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/JWTContext';


//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------
axios.defaults.headers.common['Authorization'] = `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjFlOTczZWUwZTE2ZjdlZWY0ZjkyMWQ1MGRjNjFkNzBiMmVmZWZjMTkiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcGlja3VwbmluamFhcHAiLCJhdWQiOiJwaWNrdXBuaW5qYWFwcCIsImF1dGhfdGltZSI6MTY3OTU0NjM2MywidXNlcl9pZCI6IkJJcnRlTTh5VWRmajdaTGVqS2NpMFRNblh3bDIiLCJzdWIiOiJCSXJ0ZU04eVVkZmo3WkxlaktjaTBUTW5Yd2wyIiwiaWF0IjoxNjc5NTQ2MzYzLCJleHAiOjE2Nzk1NDk5NjMsImVtYWlsIjoiaGdoZkB5b3BtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImhnaGZAeW9wbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.UyApsHPrVeY74c0H3VgaQjGc-jaaEOtz_gINzMXPsTuQoQWMdJHqKF4VGmRT4q05QIHQ239GnOW9RkaSkf_4OJaEsw7k29ZCOtS5xgBLgLQUen58FDrjAs7fLq6cM2rKBc5drbb9KjR6wdmwIk-ldEkORIWE4WigONlxVoC0CEbF-kx2qlvx7ohlR-cbwCyo5Yr4gHVnpqh9clU4t88qhKoQ_NtAe7sC0cRTohr0osaop91iUNnv7drCLA3c8IsJ2EtvYziilguOSdVQuGrCHT_S9oj_WtLqlA9UKqsqVZwOdzTqYehEHm2Gvu3rhbxv7ngArIxLA6Jd1cOE15Vi0g`;

ReactDOM.render(
  <AuthProvider>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
