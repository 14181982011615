import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, getAuth, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import SignOut from '../../../../src/images/SignOut.svg';

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     linkTo: PATH_DASHBOARD.user.profile,
//   },
//   {
//     label: 'Settings',
//     linkTo: PATH_DASHBOARD.user.account,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
const auth = getAuth();
  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  // const handleOpen = (event) => {
  //   setOpen(event.currentTarget);
  // };

  const handleClose = () => {
    setOpen(null);
  };

  // const handleLogout = async () => {
  //   try {
  //     await logout();
  //     navigate(PATH_AUTH.login, { replace: true });

  //     if (isMountedRef.current) {
  //       handleClose();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     enqueueSnackbar('Unable to logout!', { variant: 'error' });
  //   }
  // };

const handleLogout = () => {
  signOut(auth)
  .then(() => {
   navigate('/auth/login');
  })
  .catch((error) => {
    console.log(error);
  });
}
const handleOpen = () => {
  window.confirm("Are you sure you want to logout??");
}


  return (
    <>
      <div className="delIcon" onClick={handleLogout}>
        <div className='delIcon-body'></div>
        {/* <img src={SignOut} alt="jhgfg" onClick={handleLogout} /> */}
      </div>
     
    </>
  );
}

