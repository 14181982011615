import orderBy from 'lodash/orderBy';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useCallback, useState, React } from 'react';
// @mui
// import { Grid, Button, Container, Stack } from '@mui/material';
import {
  Box,
  Card,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  Container,
  Typography,
  TableContainer,
  TablePagination, Stack, Button, Grid, Paper
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
// hooks
import useSettings from '../../hooks/useSettings';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import axios from '../../utils/axiosconfig';
// utils
// import axios from '../../utils/axios';
// import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { SkeletonPostItem } from '../../components/skeleton';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';


export default function ReasonList() {

  const [reasonList, setReasonList] = useState([]);
  // const url = `https://us-central1-pickupninjaapp.cloudfunctions.net/app/api/reasons/get`;

  const theme = createTheme({
    palette: {
      primary: {
        main: '#e65100',
      },
    },
  });
  const { themeStretch } = useSettings();

  useEffect(() => {
    getReason();
  }, []);


  const Item = styled(Paper)(() => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text,
  }));

  // const getReason = async () => {
  //   try {
  //     const res = await fetch(
  //       `https://us-central1-pickupninjaapp.cloudfunctions.net/app/api/reasons/get`,
  //       {
  //         method: "GET",
  //         headers: { "Content-Type": "application/json" },
  //       }
  //     );

  //     const data = await res.json();
  //     if (data) {
  //       setReasonList(data.result);

  //       console.log(data, "....package");
  //     }
  //   }
  //   catch (error) {
  //     console.error(error);
  //   }
  // }


  const getReason = () => {
    axios.get(`/reasons/get`, {
    }).then((res) => {
      console.log(res.data);
      //  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
      setReasonList(res.data.result);
    }).catch((err) => {
      console.log("error", err);
    })
  }


  return (
    <ThemeProvider theme={theme}>
      <Page title="Reasons">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="Reasons"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Master Tables', href: PATH_DASHBOARD.reason.root },
              { name: 'Reason list' },
            ]}
            action={
              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.reason.newReason}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                New Reason
              </Button>
            }
          />

          {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
              <BlogPostsSearch />
              <BlogPostsSort query={filters} options={SORT_OPTIONS} onSort={handleChangeSort} />
            </Stack>
    
            <Grid container spacing={3}>
              {(!posts.length ? [...Array(12)] : sortedPosts).map((post, index) =>
                post ? (
                  <Grid key={post.id} item xs={12} sm={6} md={(index === 0 && 6) || 3}>
                    <BlogPostCard post={post} index={index} />
                  </Grid>
                ) : (
                  <SkeletonPostItem key={index} />
                )
              )}
            </Grid> */}
<Card>
          <Table>
                <TableHead>
                <TableRow>
                <TableCell>Reason</TableCell>
                <TableCell>Type</TableCell>
                <TableCell >Active</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {reasonList.map((row) => (
                <TableRow
                  key={row.reason}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.reason}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell >{row.isActive}</TableCell>
                
                  </TableRow>
                  ))}
                  </TableBody>
              </Table>
              </Card>

           {/* {reasonList.map((row) => ( 
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{marginBottom:"10px"}} >
            <Grid item xs={3}>
              <Item><Typography  sx={{fontSize:"16px",fontWeight:600}}>Reason -{row.reason} </Typography>
              
           
           
              <Typography  sx={{fontSize:"16px",fontWeight:600}}>Type - {row.type}</Typography>

              
            
           
              <Typography  sx={{fontSize:"16px",fontWeight:600}}>Active - {row.isActive ? "Yes":"No"}</Typography>

              </Item>
            </Grid>
            {/* <Grid item xs={3}>
    <Item><Typography variant="h6"> Special Pay- </Typography></Item>
  </Grid> */}
          {/* </Grid> */}
          

        </Container>
      </Page>
    </ThemeProvider>
  );
}