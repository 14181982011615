import orderBy from "lodash/orderBy";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useEffect, useCallback, useState, React } from "react";
// @mui
// import { Grid, Button, Container, Stack } from '@mui/material';
import {
  Box,
  Card,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Stack,
  Button,
  Grid,
  Paper,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Tab,
  Tabs,
} from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
// hooks
import useSettings from "../../hooks/useSettings";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import axios from "../../utils/axiosconfig";
// utils
// import axios from '../../utils/axios';
// import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import { SkeletonPostItem } from "../../components/skeleton";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import MenuPopover from "../../components/MenuPopover";
import rupees from "../../images/rupees.svg";
import cadmin from "../../images/clockadmin.svg";
import psb from "../../images/psb.svg";
import { useSnackbar } from "notistack";
// import MenuPopover from '../../components/MenuPopover';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RateList() {
  // const [open, setOpen] = useState(false);
  // const [Modalinputt, setModalinputt] = useState();

  const [rateList, setRateList] = useState([]);
  const [open, setOpen] = useState(false);
  const [Modalinputt, setModalinputt] = useState();
  const [id, setId] = useState(0);
  // const [value1,setValueI] = useState(30);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const url = `https://us-central1-pickupninjaapp.cloudfunctions.net/app/api/rates/get`;
  const theme = createTheme({
    palette: {
      primary: {
        main: "#e65100",
      },
    },
  });
  const { themeStretch } = useSettings();

  const [value, setValue] = useState(0);
  const [data, setDatas] = useState({
    basicRate: 0,
    basicDistance: 0,
    basicDuration: 0,
    distanceRate: 0,
    durationRate: 0,
    basicWaitingDuration: 0,
    waitingRate: 0,
    basicPay: 0,

    distancePay: 0,
    durationPay: 0,
    waitingPay: 0,
    specialPay: 0,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Item = styled(Paper)(() => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text,
  }));

  useEffect(() => {
    getRate();
  }, []);


  const getRate = () => {
    axios
      .get(`/rates/get`, {
        // config
      })
      .then((res) => {
        setRateList(res.data.result);
        setId(res.data.result[0].id);
        setDatas({
          ...data,
          basicRate: res.data.result[0].basicRate,
          basicDistance: res.data.result[0].basicDistance,
          basicDuration: res.data.result[0].basicDuration,
          distanceRate: res.data.result[0].distanceRate,
          durationRate: res.data.result[0].durationRate,
          basicWaitingDuration: res.data.result[0].basicWaitingDuration,
          waitingRate: res.data.result[0].waitingRate,
          basicPay: res.data.result[0].basicPay,
          distancePay: res.data.result[0].distancePay,
          durationPay: res.data.result[0].durationPay,
          waitingPay: res.data.result[0].waitingPay,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };


  function refreshcontrol(event) {
    event.preventDefault();
  }

  const deleteUser = (id) => {
    axios
      .delete(`rates/delete/${id}`)
      .then((res) => {
        console.log(res.data);
        setTimeout(function () {
          window.location.reload();
        }, 500);
        navigate(PATH_DASHBOARD.rate.rates);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  function handleInputt(event) {
    setModalinputt(event.target.value);
  }

  const handleSaveRateChanges = () => {
    axios
      .put(`rates/update/${id}`, data)
      .then((res) => {
        enqueueSnackbar("Rates Updated Successfully", {
          variant: "error",
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        return enqueueSnackbar("Error While Updating the Rates");
      });
  };

  return (
    <ThemeProvider>
      <Page title="Rates">
        <Container maxWidth={themeStretch ? false : 'xl'} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab sx={{ fontSize: "32px", fontWeight: 300 }} label="RATES" {...a11yProps(0)} />
                <Tab sx={{ fontSize: "32px", fontWeight: 300 }} label="OTHERS" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="tabPanel">
              <Button className='createBtn'
                variant="contained"
                style={{ background: "#E04E2C" }}
                onClick={() => handleSaveRateChanges()}
              >
                Save Changes
              </Button>
              <Box sx={{ padding: '0', marginBottom: '25px' }}>
                <Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300", borderBottom: "1px solid #E04E2C" }}>RATE CARDS</Typography>
              </Box>
              <Grid container spacing={3} style={{ marginBottom: "30px" }}>
                <Grid item xs={12} md={4} lg={3} style={{ position: "relative" }}>
                  <Box style={{ boxShadow: "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)", backgroundColor: "white" }}>
                    <Box style={{ display: "flex", justifyContent: "space-around" }}>
                      <img src={rupees} alt="rupees" />
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={true}
                            onBlur={(e) => {
                              return setDatas({
                                ...data,
                                basicRate: e.target.textContent,
                              });
                            }}
                          >
                            {data?.basicRate}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          BASE{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        padding: "0 20px",
                      }}
                    >
                      <Box style={{ display: "flex" }}>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={true}
                          onBlur={(e) => {
                            return setDatas({
                              ...data,
                              basicDistance: e.target.textContent,
                            });
                          }}
                        >
                          {data?.basicDistance}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "8px",
                          }}
                        >
                          KM
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={true}
                          onBlur={(e) => {
                            return setDatas({
                              ...data,
                              basicDuration: e.target.textContent,
                            });
                          }}
                        >
                          {data?.basicDuration}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "8px",
                          }}
                        >
                          MIN
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3} style={{ position: "relative" }}>
                  <Box style={{ boxShadow: "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)", backgroundColor: "white" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={psb} alt="rupees" style={{ height: "130px" }} />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={true}
                            onBlur={(e) => {
                              return setDatas({
                                ...data,
                                distanceRate: e.target.textContent,
                              });
                            }}
                          >
                            {data?.distanceRate}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          FARE/Km
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3} style={{ position: "relative" }}>
                  <Box style={{ boxShadow: "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)", backgroundColor: "white" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={cadmin} alt="rupees" style={{ height: "130px" }} />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={true}
                            onBlur={(e) => {
                              return setDatas({
                                ...data,
                                durationRate: e.target.textContent,
                              });
                            }}
                          >
                            {data?.durationRate}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          FARE/Min
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3} style={{ position: "relative" }}>
                  <Box style={{ boxShadow: "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)", backgroundColor: "white", padding: '0 15px' }}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Free Waiting
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "50px",
                            fontWeight: "700",
                          }}
                          contentEditable={true}
                          onBlur={(e) => {
                            return setDatas({
                              ...data,
                              basicWaitingDuration: e.target.textContent,
                            });
                          }}
                        >
                          {data?.basicWaitingDuration}
                        </Typography>
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          MIN
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Wait/Min
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={true}
                          onBlur={(e) => {
                            return setDatas({
                              ...data,
                              waitingRate: e.target.textContent,
                            });
                          }}
                        >
                          {data?.waitingRate}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          INR
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>


              <Box sx={{ padding: '0', marginBottom: '25px' }}>
                <Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300", borderBottom: "1px solid #E04E2C" }}>PAY CARDS</Typography>
              </Box>
              <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={3} style={{ position: "relative" }}>
                  <Box style={{ boxShadow: "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)", backgroundColor: "white" }}>
                    <Box
                      style={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <img src={rupees} alt="rupees" />
                      <Box>
                        <Box style={{ display: "flex" }}>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={true}
                            onBlur={(e) => {
                              return setDatas({
                                ...data,
                                basicPay: e.target.textContent,
                              });
                            }}
                          >
                            {data?.basicPay}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          BASE{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        padding: "0 20px",
                      }}
                    >
                      <Box style={{ display: "flex" }}>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={true}
                          onBlur={(e) => {
                            return setDatas({
                              ...data,
                              basicDistance: e.target.textContent,
                            });
                          }}
                        >
                          {data?.basicDistance}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "8px",
                          }}
                        >
                          KM
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={true}
                          onBlur={(e) => {
                            return setDatas({
                              ...data,
                              basicDuration: e.target.textContent,
                            });
                          }}
                        >
                          {data?.basicDuration}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "8px",
                          }}
                        >
                          MIN
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3} style={{ position: "relative" }}>
                  <Box style={{ boxShadow: "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)", backgroundColor: "white" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={psb} alt="rupees" style={{ height: "130px" }} />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={true}
                            onBlur={(e) => {
                              return setDatas({
                                ...data,
                                distancePay: e.target.textContent,
                              });
                            }}
                          >
                            {data?.distancePay}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          PAY/Km
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3} style={{ position: "relative" }}>
                  <Box style={{ boxShadow: "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)", backgroundColor: "white" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={cadmin} alt="rupees" style={{ height: "130px" }} />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={true}
                            onBlur={(e) => {
                              return setDatas({
                                ...data,
                                durationPay: e.target.textContent,
                              });
                            }}
                          >
                            {data?.durationPay}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          PAY/Min
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3} style={{ position: "relative" }}>
                  <Box style={{ boxShadow: "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)", backgroundColor: "white", padding: '0 15px' }}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Free Waiting
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "50px",
                            fontWeight: "700",
                          }}
                          contentEditable={true}
                          onBlur={(e) => {
                            return setDatas({
                              ...data,
                              basicWaitingDuration: e.target.textContent,
                            });
                          }}
                        >
                          {data?.basicWaitingDuration}
                        </Typography>
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          MIN
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Wait/Min
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={true}
                          onBlur={(e) => {
                            return setDatas({
                              ...data,
                              waitingPay: e.target.textContent,
                            });
                          }}
                        >
                          {data?.waitingPay || 3}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          INR
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} className="tabPanel">
              <Box sx={{ padding: '0', marginBottom: '25px' }}>
                <Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300", borderBottom: "1px solid #E04E2C" }}>OTHERS</Typography>
              </Box>
            </TabPanel>
          </Box>
        </Container>
      </Page>
    </ThemeProvider>
  );
}
