import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { useForm } from "react-hook-form";
import { Link, Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PATH_AUTH } from "../../../routes/paths";
import { useContext } from "react";
import { MainContext } from "src/contexts/context";
import Iconify from "../../../components/Iconify";
import axios from "../../../utils/axiosconfig";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = getAuth();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    // resolver: yupResolver(LoginSchema),
    // defaultValues,
  });
  const history = useNavigate();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    refreshPage();
  }, []);

  const refreshPage = () => {
    localStorage.clear();
  };
  const getManager = (managerId) => {
    axios
      .get(`managers/get/uId/${managerId}`)
      .then((res) => {
        localStorage.setItem("manager_city", res.data.result.cityId);
        localStorage.setItem("manager_id", res.data.result.id);
        history("/dashboard");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const handleLogin = (e) => {
    localStorage.clear();
    signInWithEmailAndPassword(auth, email, password)
      .then((obj) => {
        console.log("test12.....", obj);
        localStorage.setItem("mid", obj.user?.uid);
        obj.user?.getIdToken(true).then((idToken) => {
          console.log(idToken);

          localStorage.setItem("pickup-ninja-token", idToken);
          getManager(obj.user?.uid);
          enqueueSnackbar("Login Successful");
        });
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/invalid-email":
          case "auth/user-disabeled":
          case "auth/user-not-found":
            enqueueSnackbar(err.message, { variant: "error" });
            break;
          case "auth/wrong-password":
            enqueueSnackbar(err.message, { variant: "error" });
            break;
          default:
            enqueueSnackbar(err.message, { variant: "error" });
            break;
        }
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleLogin)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />

        <RHFTextField
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox name="remember" label="Remember me" />
        <Link
          component={RouterLink}
          variant="subtitle2"
          to={PATH_AUTH.resetPassword}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
