import { useContext } from 'react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedTextFieldProps,
  Tab,
  Tabs,
  Box,
  IconButton, MenuItem
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import axios from '../../utils/axiosconfig';
import MenuPopover from 'src/components/MenuPopover';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user/list';
import Image from 'src/components/Image';
import avatar from '../../images/avatar.svg';
import Campaigns from '../campaigns/campaigns';

// sections
import { groupListHead, groupListToolbar, UserMoreMenu } from '../../sections/@dashboard/user/list';
import { MainContext } from 'src/contexts/context';
import CouponList from '../coupons/couponList';
// import GroupList from './GroupList';


// tab start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// tab end



function Innerpage(dataaa) {
  const { children, c_Data, index, ...other } = dataaa;

  return (
    <div
      role="Innerpage"
      hidden={c_Data !== index}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      {...other}
    >
      {c_Data === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function abc(index) {
  return {
    id: `main-tab-${index}`,
    'aria-controls': `main-tabpanel-${index}`,
  };
}


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Icon', alignRight: false },
  { id: 'company', label: 'Title', alignRight: false },
  { id: 'role', label: 'Message', alignRight: false },
  { id: 'role', label: 'Default', alignRight: true },
  { id: 'role', label: 'Active', alignRight: true },
  { id: 'Action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function NotificationList() {
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const { managerId } = useContext(MainContext);
  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [value, setValue] = useState(0);
  const [deleted, setDeleted] = useState('');
  const [type, setType] = useState('all');
  const [limit, setLimit] = useState(0);
  const [filterData, setFilterData] = useState();
  const [filterValue, setFilterValue] = useState();
  const [searchValue, setSearchValue] = useState();
  const [searchPayload, setSearchPayload] = useState();
  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#e65100',
  //     },
  //   },
  // });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [c_Data, setc_Data] = useState(0);

  const pageChange = (event, newc_Data) => {
    setc_Data(newc_Data);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = notificationList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (userId) => {
    const deleteUser = notificationList.filter((user) => user.id !== userId);
    setSelected([]);
    setNotificationList(deleteUser);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = notificationList.filter((user) => !selected.includes(user.name));
    setSelected([]);
    setNotificationList(deleteUsers);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - notificationList.length) : 0;

  const filteredUsers = applySortFilter(notificationList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && Boolean(filterName);


  useEffect(() => {
    if(managerId){
    getNotification();
    }
  }
    , [type, searchPayload,managerId,deleted]);

  useEffect(() => {
    const search = () => {
      if (filterValue === 'isDefault') {
        setSearchPayload(true)
      } else {
        setSearchPayload(searchValue)
      }
    }
    if (filterValue) {
      search()
    }
  }, [searchValue, filterValue])

  const getNotification = async () => {
    const payload = {};
    payload.managerId = managerId;
    payload.type = type;
    payload.filter = filterValue;
    payload.search = searchPayload;
    payload.page = page;
    payload.limit = limit;
    try {
      const res = await axios.post(`/notifications/get/all`, payload)
      setNotificationList(res.data.result);
      setFilterData(res.data.filters)
    }
    catch (e) {
      console.log(e)
    }
  }


  const deleteNotification = (id, e) => {
    console.log("testing", id);
    axios.delete(`notifications/delete/${id}`).then((res) => {
      console.log(res.data);
      setDeleted(res.data.result);

    }).catch((err) => {
      console.log("error", err)
    })
  }



  return (
    <ThemeProvider>
      <Page title="Notifications">
        <Container maxWidth={themeStretch ? false : 'xl'} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="NOTIFICATION" {...a11yProps(0)} style={{ color: '#030303', fontSize: '32px', fontWeight: '300' }} />
                <Tab label="CAMPAIGNS" {...a11yProps(1)} style={{ color: '#030303', fontSize: '32px', fontWeight: '300' }} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} className="tabPanel">

              <Button className='createBtn'
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.notification.newNotification}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                style={{ background: '#E04E2C' }}
              >
                New Notification
              </Button>
              <Box style={{ padding: '0' }}>

                <Card sx={{borderRadius:'0'}}>

                  <Innerpage value={c_Data} index={0}>

                  </Innerpage>
                  <Innerpage value={c_Data} index={1}>
                    Item Two
                  </Innerpage>
                  <Innerpage value={c_Data} index={2}>
                    Item Three
                  </Innerpage>
                  <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    onDeleteUsers={() => handleDeleteMultiUser(selected)}
                    setFilterValue={setFilterValue}
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    filterData={filterData}
                  />


                  <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 375px)' }}>
                    <Scrollbar>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={notificationList.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                            const isItemSelected = selected.indexOf(row?.id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={row?.id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell sx={{ display: 'flex', alignItems: 'center' }} >
                                  {row?.icon !== null ? (<Image
                                    disabledEffect
                                    alt="image"
                                    src={row?.icon}
                                    sx={{ borderRadius: '50%', width: 64, height: 64, }}
                                  />) : (<Image
                                    disabledEffect
                                    alt="image"
                                    src={avatar}
                                    sx={{ borderRadius: '50%', width: 64, height: 64, }}
                                  />)}</TableCell>
                                <TableCell component="th" scope="row">
                                  {row?.title}
                                </TableCell>
                                <TableCell >{row?.message}</TableCell>



                                {/* <TableCell align='center'>{row.url}</TableCell> */}
                                {/* <TableCell >{row.isDefault ? "No":"Yes"}</TableCell>
                  <TableCell>{row.isActive ? "Yes":"No"}</TableCell> */}
                                <TableCell align="right"><Label color={!row?.isDefault ? "error" : "success"}>{row?.isDefault ? 'Yes' : 'No'}</Label></TableCell>
                                <TableCell align="right"><Label color={row?.isActive ? "success" : "error"}>{row?.isActive ? 'Yes' : 'No'}</Label></TableCell>

                                <TableCell align="right">
                                  <ThreedotMenu row={row} deleteNotification={deleteNotification} />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        { notificationList?.length===0 && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={15} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={searchValue} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </Scrollbar>

                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={notificationList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, page) => setPage(page)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1} className="tabPanel">
            <Button className='createBtn'
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.campaigns.newCampaign}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                style={{ background: '#E04E2C' }}
              >
                New Campaign
              </Button>
              <Campaigns />
            </TabPanel>

          </Box>
        </Container>
      </Page>
    </ThemeProvider>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export function ThreedotMenu({ row, deleteNotification }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >

        <MenuItem onClick={() => deleteNotification(row?.id)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} style={{ marginRight: "5px" }} />
          Delete
        </MenuItem>

        <MenuItem component={RouterLink} to={`${PATH_DASHBOARD.notification.editById}/${row?.id}`}>
          <Iconify icon={'eva:edit-fill'} style={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  )
}