import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Conformation from "../../components/Conformation";
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tab,
  Tabs,
  Box,
  IconButton,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import MenuPopover from "src/components/MenuPopover";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoupons,reset } from "src/redux/slices/GetCoupons";
import LoadingScreen from "src/components/LoadingScreen";
import DoneIcon from "@mui/icons-material/Done";
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CloseIcon from "@mui/icons-material/Close";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TABLE_HEAD = [
  { id: "name", label: "Code", alignRight: false },
  { id: "company", label: "Type", alignRight: false },
  { id: "role", label: "Discount", alignRight: false },
  { id: "role", label: "User Limit", alignRight: true },
  { id: "isVerified", label: "Total Limit", alignRight: true },
  { id: "status", label: "Start Time", alignRight: true },
  { id: "role", label: "End Time", alignRight: true },
  { id: "status", label: "Active", alignRight: true },
  {id: "Promoted", label: "Promoted", alignRight: true},
  { id: "Action", label: "Action", alignRight: true },

];

// ----------------------------------------------------------------------

export default function CouponList() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getCoupons);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [couponList, setCouponList] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [value, setValue] = useState(0);
  const [updated, setUpdated] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [updateObj, setUpdateObj] = useState({ id: null});
  const [filterData, setFilterData] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = couponList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = couponList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setCouponList(deleteUsers);
  };
  const isNotFound = couponList?.length == 0 ? true : false;

  const deleteCoupon = () => {
    setOpen(false);
   
      axios
        .delete(`coupons/delete/${updateObj.id}`)
        .then((res) => {
          console.log(res.data);
          setUpdated(true);
        })
        .catch((err) => {
          console.log("error", err);
        });
    
  };
  useEffect(() => {
    dispatch(reset([]));
  }, []);
  useEffect(() => {
    if (filterValue?.length > 0 && searchValue?.length >= 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    filterSearch();
  }, [rowsPerPage, page, updated]);
  useEffect(() => {
    getCoupon();
  }, [state]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.cityId = localStorage.getItem("manager_city");
      payload.type = "all";
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      console.log("all coupons payload........", payload);
      await axios
        .post("/coupons/get/all", payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.filter = "";
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchCoupons(payload));
          } else {
            setCouponList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getCoupon = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchCoupons(payload));
      } else {
        setCouponList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const Convert = (dt) => {
    if (!dt) return "";
    let m = new Date(dt).getHours();
    let n = m > 12 ? "PM" : "AM";
    const dd = `${new Date(dt).getDate()} ${new Date(dt).toLocaleString(
      "en-US",
      { month: "long" }
    )} ${new Date(dt).getFullYear()} ${new Date(dt).getHours()}:${new Date(
      dt
    ).getMinutes()} ${n}`;
    return dd;
  };

  return (
    <ThemeProvider>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Coupons">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="COUPONS"
                  {...a11yProps(0)}
                  style={{
                    color: "#030303",
                    fontSize: "32px",
                    fontWeight: "300",
                  }}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} className="tabPanel">
              <Button
                className="createBtn"
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.coupon.newCoupon}
                startIcon={<Iconify icon={"eva:plus-fill"} />}
                style={{ background: "#E04E2C" }}
              >
                New Coupon
              </Button>
              <Box style={{ padding: "0" }}>
                <Card sx={{ borderRadius: "0" }}>
                  <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    onDeleteUsers={() => handleDeleteMultiUser(selected)}
                    setFilterValue={setFilterValue}
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    filterData={filterData}
                  />

                  <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
                    <Scrollbar>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={couponList.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {couponList?.map((row) => {
                            const isItemSelected =
                              selected.indexOf(row.id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell>{row?.code || " "}</TableCell>

                                <TableCell>{row?.type}</TableCell>
                                <TableCell>{row?.discount}</TableCell>
                                <TableCell align="right">
                                  {row?.userLimit}
                                </TableCell>
                                <TableCell align="right">
                                  {row?.totalLimit}
                                </TableCell>
                                <TableCell align="right">
                                  {Convert(row?.startTime)}
                                </TableCell>
                                <TableCell align="right">
                                  {Convert(row?.endTime)}
                                </TableCell>
                                <TableCell align="right">
                                  {row?.isActive === true ? (
                                    <DoneIcon sx={{ color: "green" }} />
                                  ) : (
                                    <CloseIcon sx={{ color: "red" }} />
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {row?.promoted ? <GppMaybeIcon sx={{color:"red"}}/> : <GppGoodIcon sx={{color:"green"}}/>}
                                </TableCell>
                                <TableCell align="right">
                                  <ThreedotMenu
                                    row={row}
                                    setUpdateObj={setUpdateObj}
                                    setOpenHandler={setOpen}
                                  />
                                </TableCell>
                                
                              </TableRow>
                            );
                          })}
                          {isNotFound && (
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{ py: 3 }}
                                colSpan={15}
                              >
                                No data found.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>

                  <TablePagination
                     rowsPerPageOptions={[50, 100, 250]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={(e, page) => setPage(page + 1)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </Box>
            </TabPanel>
          </Box>
        </Container>
        <Conformation
          open={open}
          onClose={() => setOpen(false)}
          handleSubmit={deleteCoupon}
          title= "Delete Confirmation"
          subTitle="Do you want to Delete Coupon"
        />
      </Page>
    </ThemeProvider>
  );
}

export function ThreedotMenu({ row, setUpdateObj, setOpenHandler }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenHandler(true);
            handleClose();
            setUpdateObj({ id: row?.id});
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify
            icon={"eva:trash-2-outline"}
            style={{ marginRight: "5px" }}
          />
          Delete
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`${PATH_DASHBOARD.coupon.editById}/${row?.id}`}
          state={{ fromDashboard: true }}
        >
          <Iconify icon={"eva:edit-fill"} style={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}
