import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axiosconfig";
export const fetchGroups = createAsyncThunk("getGroups", async (payload) => {
  payload.managerId = localStorage.getItem("manager_id");
  const response = await axios.post("/groups/get/all", payload);
  return response;
});
const getGroups = createSlice({
  name: "getGroups",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = [];
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroups.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchGroups.rejected, (state, action) => {
      console.log("Error............", action.payload);
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const { reset } = getGroups.actions;
export default getGroups.reducer;
