import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import axios from '../../../src/utils/axiosconfig';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
// @mui
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  MenuItem,Box,Tab,Tabs
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
// import { _userList } from '../../_mock';
import MenuPopover from '../../components/MenuPopover';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user/list';

// ----------------------------------------------------------------------



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// tab end



function Innerpage(dataaa) {
  const { children, c_Data, index, ...other } = dataaa;

  return (
    <div
      role="Innerpage"
      hidden={c_Data !== index}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      {...other}
    >
      {c_Data === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const TABLE_HEAD = [
  { id: 'name', label: 'City', alignRight: false },
  { id: 'company', label: 'State', alignRight: false },
  { id: 'role', label: 'Country', alignRight: false },

  { id: 'isVerified', label: 'Active', alignRight: true },
  { id: 'currency', label: "Currency", alignRight: true },
  { id: 'latitude', label: 'Latitude', alignRight: true },
  { id: 'longitude', label: 'Longitude', alignRight: true },

  {  label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function Cities() {
  // const theme = useTheme();
  const { themeStretch } = useSettings();


  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [cityList, setCityList] = useState([]);
  const [cityId,setCityId] = useState();
  const [open, setOpen] = useState(null);
  const [value,setValue] = useState(0);
  const [c_Data,setc_Data] = useState(0)
  const url='https://us-central1-pickupninjaapp.cloudfunctions.net/app/api/cities/get';



  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = cityList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (city) => {
    const deleteUser = cityList.filter((user) => user.city !== city);
    setSelected([]);
    setCityList(deleteUser);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = cityList.filter((user) => !selected.includes(user.name));
    setSelected([]);
    setCityList(deleteUsers);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - cityList.length) : 0;

  const filteredUsers = applySortFilter(cityList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && Boolean(filterName);


  const theme = createTheme({
    palette: {
      primary: {
        main: '#e65100',
      },
    },
  });

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    getCity();
  }, []);




  const getCity =()=>{
    axios.get("cities/get").then((res)=>{
        console.log(res.data);
        //  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
        setCityList(res.data.result);
        
    }).catch((err)=>{
      console.log("error",err)
    })
  }
  
  const deleteCity = (id) => {
    axios.delete(`cities/delete/${id}`).then((res) => {
      console.log(res.data);
    
    }).catch((err) => {
      console.log("error", err)
    })
  }




  console.log(cityList.length);

  return (
    <ThemeProvider>
    <Page title="Cities">
      <Container maxWidth={themeStretch ? false : 'xl'} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <Box sx={{ width: "100%" }}>
          <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="CITIES" {...a11yProps(0)} style={{ color: '#030303', fontSize: '32px', fontWeight: '300' }} />

            </Tabs>
          </Box>

          <TabPanel value={value} index={0} className="tabPanel">

            <Button className='createBtn'
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.cities.newCity}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              style={{ background: '#E04E2C' }}
            >
              New City
            </Button>
            <Box style={{ padding: '0' }}>

              <Card sx={{borderRadius:'0'}}>

                <Innerpage value={c_Data} index={0}>

                </Innerpage>
                <Innerpage value={c_Data} index={1}>
                  Item Two
                </Innerpage>
                <Innerpage value={c_Data} index={2}>
                  Item Three
                </Innerpage>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  onDeleteUsers={() => handleDeleteMultiUser(selected)}
                />


                <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 375px)' }}>
                  <Scrollbar>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={cityList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                          const isItemSelected = selected.indexOf(row.id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox checked={isItemSelected} onClick={() => handleClick(row.id)} />
                              </TableCell>
                              <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt={row.city} sx={{ bgcolor: '#e65100',color:"white", mr: 2 }} >
                              <ApartmentOutlinedIcon /> </Avatar>
                            <Typography variant="subtitle2" noWrap>
                              {row.city}
                            </Typography>
                          </TableCell>
                              <TableCell >
                                {row.state}
                              </TableCell>
                              <TableCell >{row.country}</TableCell>
                              <TableCell align="right"><Label color={!row.isActive ? "error" : "success"}>{row.isActive ? 'Yes' : 'No'}</Label></TableCell>
                              <TableCell align="right">{row.currency}</TableCell>
                              <TableCell align="right">{row.latitude}</TableCell>
                              <TableCell align="right">{row.longitude}</TableCell>
                              <TableCell align="right">
                                  <ThreedotMenu row={row} deleteCity={deleteCity} />
                                </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      { cityList?.length===0 && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              {/* <SearchNotFound searchQuery={searchValue} /> */}
                              No data Found
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </Scrollbar>

                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={cityList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, page) => setPage(page)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Box>
          </TabPanel>


        </Box>
      </Container>
    </Page>
  </ThemeProvider>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
export function ThreedotMenu({ row, deleteCity }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >

        <MenuItem onClick={() => deleteCity(row?.id)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} style={{ marginRight: "5px" }} />
          Delete
        </MenuItem>

        <MenuItem component={RouterLink} to={`${PATH_DASHBOARD.cities.editById}/${row?.id}`}>
          <Iconify icon={'eva:edit-fill'} style={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  )
}