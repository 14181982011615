import React, { useState, useEffect } from "react";
import BookingDetailsTab from "src/components/common-component/BookingDetailsTab";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// @mui
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import larrow from "../../images/arrow-left.svg";
import plusSquare from "../../images/plus-square.svg";
import RiderImage from "../../images/rider.svg";
import ArrowLeft from "../../images/arrow-circle-left.svg";
import ArrowRight from "../../images/arrow-circle-right.svg";
import HourglassHigh from "../../images/HourglassHigh.svg";


import {
  Card,
  Table,
  Avatar,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Tab,
  Tabs,
  Box,
  IconButton,
  MenuItem,
  Grid,
  Slider,
  Rating,
  Chip,
  Badge,
  Skeleton,
  CircularProgress
} from "@mui/material";

import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { styled } from "@mui/material/styles";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import c_circle from "../../images/call-circle.svg";
import m_circle from "../../images/message-circle.svg";
import bill from "../../images/bill.png";
import correct from "../../images/correct.png";
import ninja_head from "../../images/ninja_head.png";
import axios from "../../utils/axiosconfig";
import MenuPopover from "src/components/MenuPopover";
import verified_user from "../../images/SealCheck.png";

const marks = [
  {
    value: 0,
    label: "",
  },
  {
    value: 25,
    label: "0.5 KM",
  },
  {
    value: 75,
    label: "4.5 KM",
  },
  {
    value: 100,
    label: "",
  },
];

// tab start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TABLE_HEAD = [
  { id: "name", label: "Booking Number", alignRight: false },
  { id: "company", label: "Customer", alignRight: false },
  { id: "role", label: "Package Type", alignRight: false },
  { id: "isVerified", label: "Time", alignRight: true },
  { id: "status", label: "Fragile", alignRight: true },
  { id: "status", label: "Status", alignRight: true },
  { id: "Action", label: "Action", alignRight: true },
];

const style = {
  container: {
    backgroundColor: "#F9F9F9",
    borderRadius: "17px",
    padding: "8px 17px",
    marginTop: "5px",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2px",
  },   
  heading: {
    color: "#E3552E",
    fontSize: "10px",
    fontWeight: "700",
  },
  instructioncontainer: {
    backgroundColor: "#F9F9F9",
    borderRadius: "20px",
    padding: "15px 20px",
    marginTop: "11px",
    marginRight: "5px",
  },
  textCustName: {
    color: "#002236",
    fontSize: "14px",
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textHead: {
    color: "#002236",
    fontSize: "13px",
    fontWeight: "600",
  },
  textSubhead: {
    color: "#002236",
    fontSize: "10px",
    fontWeight: "400",
  },
  img: {
    height: "18px",
  },
};
export default function BookingDetailsgs() {
  const [loading, setLoading] = useState(true);
  const [Id, setId] = useState();
  const [amount, setAmount] = useState(0);
  const [packageType, setpackageType] = useState();
  const [packageIcon, setpackageIcon] = useState();
  const [distance, setDistance] = useState(0);
  const [couponId, setCoupnId] = useState(0);
  const [gst, setGst] = useState(0);
  const [rateList, setRateList] = useState([]);
  const [couponType, setCouponType] = useState();
  const [discount, setDiscount] = useState();
  const [bookingstageobj, setBookingstageobj] = useState();
  const [scheduleTime, setScheduleTime] = useState();
  const [userName, setuserName] = useState();
  const [userMessage, setUserMessage] = useState();
  const [userMobile, setuserMobile] = useState();
  const [userAvatar, setuserAvatar] = useState();
  const [gstval, setGstVal] = useState(0);
  const [bookingNumber, setBookingNumber] = useState();
  // const hidePayment = () => setShow(true);
  const hideRiderDetails = () => setDetails(true);
  const hidehandelOnClickPlus = () => setForm(false);
  const [totalFee, setTotalFee] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const location = useLocation();
  const from = location.state.data;
  const tableData = location.state.tableData;
  const [activeBookingId, setActiveBookingId] = useState(from.id);
  const [activeIndex, setActiveIndex] = useState(location.state.index);
  const [creatingTime, setCreatingTime] = useState();
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [response, setResponse] = useState();
  const [bookingList, setBookingList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [value, setValue] = useState(0);
  const [userData, setUserData] = useState();
  const [riderList, setRiderList] = useState([]);
  const [deleted, setDeleted] = useState("");
  const [libraries] = useState(["geometry"]);
  const [userObjFromBooking, setUserObjFromBooking] = useState();
  const [tasksList, setTasksList] = useState();
  const [transactionDetails, setTransactionDetails] = useState();
  const [accepteddistance, setAcceptedDistance] = useState();
  const [pickuptodrop, setPickupToDrop] = useState();
  const [dateTime, setDateTime] = useState("");
  const [desName, setDesName] = useState();
  const [desName1, setDesName1] = useState();
  const [landMark, setLandMark] = useState();
  const [landMark1, setLandMark1] = useState();
  const [desAddress1, setDesAddress1] = useState();
  const [desAddress, setDesAddress] = useState();
  const [desMobile, setDesMobile] = useState();
  const [desMobile1, setDesMobile1] = useState();
  const [longitude, setLongitude] = useState();
  const [longitude1, setLongitude1] = useState();
  const [latitude, setLatitude] = useState();
  const [latitude1, setLatitude1] = useState();
  const [isRiderLoading, setIsRiderLoading] = useState(true);
  // const [show, setShow] = useState(true);
  const [details, setDetails] = useState(true);

  const [form, setForm] = useState(false);

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };
  const handleDateTimeChange = (event) => {
    setDateTime(event.target.value);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const [name, setName] = useState("");

  const [price, setPrice] = useState(null);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const transactionAdd = () => {};
  const isButtonDisabled = !name || !price;

  const openPay = () => {
    // setShow(false);
  };
  const closePay = () => {
    // setShow(true);
  };
  const handleOnClickPlus = () => {
    setForm(true);
  };

  const openRiderDetails = () => {
    setDetails(false);
  };
  const closeRiderDetails = () => {
    setDetails(true);
  };
  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#e65100',
  //     },
  //   },
  // });
  const previousData = () => {
    let newIndex = activeIndex - 1;
    if (newIndex < 0) {
      newIndex = tableData.length - 1;
    }
    setActiveIndex(newIndex);
    setActiveBookingId(tableData[newIndex]?.id);
  };
  const nextData = () => {
    let newIndex = activeIndex + 1;
    if (newIndex >= tableData.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
    setActiveBookingId(tableData[newIndex]?.id);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [c_Data, setc_Data] = useState(0);

  const pageChange = (event, newc_Data) => {
    setc_Data(newc_Data);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = bookingList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const copyMobileNo=(mobileNo)=>{
    navigator.clipboard
    .writeText(mobileNo)
    .then(() => {
      enqueueSnackbar("Copied to clipboard.", {
        style: { marginTop: "100px" },
      });
    })
    .catch((error) => {
      console.log("Failed to copy link:", error);
    });
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (userId) => {
    const deleteUser = bookingList.filter((user) => user.id !== userId);
    setSelected([]);
    setBookingList(deleteUser);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = bookingList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setBookingList(deleteUsers);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookingList.length) : 0;

  const filteredUsers = applySortFilter(
    bookingList,
    getComparator(order, orderBy),
    filterName
  );
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const isNotFound = !filteredUsers.length && Boolean(filterName);
  const isNotFounds = transactionDetails?.length == 0 ? true : false;
 
  const addTransactions = () => {
    
    if (name.length > 0 && price !== null) {
      let payload = {
        bookingId: activeBookingId,
        transactionId: name,
        distance: distance,
        amount: price,
        type: "offline",
        isSuccessful: true,
        status: "success",
      };
      // console.log("testtesttest",payload);
      axios
        .post(`/transactions/admin-add`, payload)
        .then((res) => {
          setForm(false);
          setTransactionDetails(res.res.data);
          console.log("testing", res);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const getBookingDetails = async () => {
    await axios
      .get(`/bookings/get/bookingDetails/${activeBookingId}`)
      .then((res) => {
        console.log("oooooooooo", res.data.result);
        setId("oooooooooo", res.data.result.id);
        setpackageType(res.data.result.packageTypeObj?.type);
        setpackageIcon(res.data.result.packageTypeObj?.icon);
        console.log("ppppp", res.data.result.packageTypeObj?.type);
        setAcceptedDistance(res.data.result?.acceptedCoordinates?.distance);
        setPickupToDrop(res.data.result?.distance);
        setAmount(res.data.result?.amount);
        setDistance(res.data.result?.distance);
        setCoupnId(res.data.result?.couponId);
        setGst(res.data.result?.gst);
        setDiscount(res.data.result?.discount);
        setBookingNumber(res.data.result?.bookingNumber);
        setBookingstageobj(res.data.result.stageId);
        setScheduleTime(res.data.result?.scheduleBooking);
        setCreatingTime(res.data.result?.created);
        // setRateList(res.get.data.rate);
        // console.log('ddddddddddddddddddddddddddd',res.data.result.rate);
      })
      .catch((e) => {
        console.log(" Booking Details error", e);
      });
  };

  const getGst = () => {
    axios
      .get(`/gst/get`)
      .then((res) => {
        setGst(res.data.result.gst);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getRate = () => {
    axios
      .get(`/rates/get`, {
        // config
      })
      .then((res) => {
        // console.log("ratessData=======",res.data.result)
        setRateList(res.data.result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    if (rateList) {
      if (distance <= rateList[0]?.basicDistance) {
        let totalCost =
          parseInt(rateList[0]?.basicRate) + parseInt(rateList[0]?.specialPay);
        setTotalFee(Math.ceil(totalCost));
      } else {
        let extraDistance = distance - rateList[0]?.basicDistance;
        let extraFee = extraDistance * parseInt(rateList[0]?.distanceRate);
        let totalCost = (
          parseInt(rateList[0]?.basicRate) +
          parseInt(rateList[0]?.specialPay) +
          extraFee
        ).toFixed(2);
        setTotalFee(Math.ceil(totalCost));
      }
    }
  }, [from.id, rateList]);

  const TotalDiscount =
    couponType === "flat" ? discount : parseInt((totalFee * discount) / 100);
  const TotalFeeDisc = totalFee - TotalDiscount;
  const TotalFeeGst = totalFee + gst;
  const totaldistance =setTransactionDetails
    accepteddistance && pickuptodrop
      ? parseFloat(
          (parseFloat(accepteddistance) + parseFloat(pickuptodrop)).toFixed(2)
        )
      : parseFloat(pickuptodrop);

  const getTransaction = () => {
    axios
      .get(`/bookings/get/transactions/${activeBookingId}`)
      .then((res) => {
        setTransactionDetails(res.data.result);
      })
      .catch((e) => {
        console.log("Details Transaction error", e);
      });
  };

  // rider details from API
  const getRiderDetails = () => {
    axios
      .get(`/bookings/get/riderDetails/${activeBookingId}`)
      .then((res) => {
        console.log("qqqqq", res.data.result);
        setRiderList(res.data.result || []);
        setIsRiderLoading(false);
      })
      .catch((err) => console.log("Error in rider details", err));
  };

  //    calling customer details
  const setUserInfo = async () => {
    try {
      const res = await axios.get(
        `/bookings/admin/dashboard/${activeBookingId}`
      );
      const data = res?.data?.result;
      console.log("222222222", res.data.result);
      setUserObjFromBooking(res.data.result);
      setTasksList(res.data.result.tasksList);
      setuserName(res.data.result.user.name);
      setuserMobile(res.data.result.user.mobile);
      setuserAvatar(res.data.result.user.avatar);
      setUserMessage(res.data.result.message);
      setDesName(data?.tasksList[0]?.destination?.name);
      setDesName1(data?.tasksList[1]?.destination?.name);
      setLandMark(data?.tasksList[0]?.destination?.landMark);
      setLandMark1(data?.tasksList[1]?.destination?.landMark);
      setDesAddress(data?.tasksList[0]?.destination?.address);
      setDesAddress1(data?.tasksList[1]?.destination?.address);
      setDesMobile(data?.tasksList[0]?.destination.mobile);
      setDesMobile1(data?.tasksList[1]?.destination.mobile);
      setLongitude(data?.tasksList[0]?.destination.longitude);
      setLongitude1(data?.tasksList[1]?.destination.longitude);
      setLatitude(data?.tasksList[0]?.destination.latitude);
      setLatitude1(data?.tasksList[1]?.destination.latitude);
    } catch (e) {
      console.log(e);
      //  alert("error in data fetching")
    }
  };
  useEffect(() => {
    getTransaction();
    setUserInfo();
    getRiderDetails();
  }, [form, activeIndex]);

  useEffect(() => {
    getBookingDetails();
    getGst();
    getRate();
  }, [activeIndex]);

  const deleteBooking = (id) => {
    axios
      .delete(`bookings/delete/${id}`)
      .then((res) => {
        console.log(res.data);
        setDeleted(res.data.result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const object = bookingList.find((obj) => obj.bookingNumber === "00010");

  const Convert = (dt) => {
    let m = new Date(dt).getHours() || "";
    let n = m >= 12 ? "PM" || "" : "AM" || "";
    const mins = new Date(dt).getMinutes() || "";
    const zero = mins < 10 ? "0" : "" || "";
    const h = m > 12 ? m - 12 : m || "";
    const dd = `${h}:${zero}${mins} ${n}` || "";
    return dd || "";
  };
  const ConvertDt = (dt) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = new Date(dt).getDate() || "";
    const monthIndex = new Date(dt).getMonth() || "";
    const month = months[monthIndex] || "";
    const year = new Date(dt).getFullYear() || "";

    let suffix = "th" || "";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st" || "";
    } else if (day === 2 || day === 22) {
      suffix = "nd" || "";
    } else if (day === 3 || day === 23) {
      suffix = "rd" || "";
    }

    return day + suffix + " " + month;
  };
  function valuetext(value) {
    return `${value}`;
  }
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
  }));

  const containerStyle = {
    height: "calc(100vh - 120px)",
  };

  const center = {
    lat: 20.28114,
    lng: 85.79215,
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAy_K9v6abejZ2FeiUW6q87b44t0sE2QsI",
    libraries: libraries,
  });

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (!isLoaded) return "loading maps";

  const riderPersonalDetail = () => {
    if (riderList instanceof Array) {
      return riderList.map((items) => {
        const formattedDateTime = new Date(items.created).toLocaleString();
        let statusStyle = {};

        // Define styles based on status
        if (items.status === "accepted") {
          statusStyle = { background: "#A5E4B6", color: "#04562A" };
        } else if (items.status === "requested") {
          statusStyle = { color: "#8B5C0E", backgroundColor: "#F7E4BA" };
        } else if (items.status === "cancelled") {
          statusStyle = { color: "#BB193B", backgroundColor: " #F3C0C7" };
        } else if (items.status === "completed") {
          statusStyle = { background: "#A5E4B6", color: "#04562A" };
        }
        return (
          <Box style={{ display: "flex" }}>
            <Typography
              style={{
                padding: "4px",
                margin: "5px",
                borderRadius: "7px",
                color: "black",
                ...statusStyle,
              }}
            >
              {items.status?.replace(
                /(\w)(\w*)/g,
                (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
              ) || ""}
            </Typography>
            <Typography
              style={{
                padding: "2px",
                margin: "5px",
                color: "blue",
                fontWeight: 400,
              }}
            >
              {formattedDateTime}
            </Typography>
          </Box>
        );
      });
    } else {
      return <Typography>Loading...</Typography>;
    }
  };
  const RiderSkeletonCard = () => {
    return (
      <Box
        sx={{
          border: "1px solid #EBEBEB",
          padding: 2,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Skeleton
            variant="circular"
            animation="wave"
            width="3.125vw"
            height="3.125vw"
            sx={{ marginRight: "15px" }}
          />

          <Box
            sx={{
              flexDirection: "column",
              marginRight: "15px",
            }}
          >
            <Typography variant="body1">
              <Skeleton variant="text" width="5vw" />
            </Typography>

            <Typography variant="body1">
              <Skeleton variant="text" width="4vw" sx={{ fontSize: "1rem" }} />
            </Typography>
          </Box>
          <Box>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="5vw"
              height="2vw"
              sx={{
                borderRadius: "18px",
              }}
            />
          </Box>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              gap: "5px",
            }}
          >
            <Skeleton
              variant="circular"
              animation="wave"
              width="2.6vw"
              height="2.6vw"
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width="2.6vw"
              height="2.6vw"
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width="2.6vw"
              height="2.6vw"
            />
          </Box>
        </Box>
      </Box>
    );
  };
  const riderDetailing = () => {
    const byStatus = riderList.filter((item) => item.status != null);
    const filteredTime = byStatus.filter((e) => e.created != null);

    let maxCreatedValue = -Infinity;
    let riderObjWithMaxCreated = null;

    filteredTime.forEach((object) => {
      if (object.created > maxCreatedValue) {
        maxCreatedValue = object.created;
        riderObjWithMaxCreated = object.riderObj;
      }
    });
    if (isRiderLoading) {
      return <RiderSkeletonCard />;
    }
    if (riderObjWithMaxCreated && byStatus) {
      const byStatusData = byStatus;
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
          key={riderObjWithMaxCreated.id}
        >
          <Box style={{ display: "flex", alignItems: "center", width: "80%" }}>
            <Badge
              style={{ marginRight: "9px" }}
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={<SmallAvatar src={verified_user} />}
            >
              <Avatar src={riderObjWithMaxCreated.avatar} />
            </Badge>
            <Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {riderObjWithMaxCreated.name?.replace(
                    /(\w)(\w*)/g,
                    (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                  ) || ""}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 12,
                    fontFamily: "'Cabin', sans-serif",
                    color: "#7c7c7c",
                  }}
                >
                  <Box
                    sx={{
                      minHeight: "5px",
                      width: "5px",
                      background: "#7c7c7c",
                      borderRadius: "50%",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  ></Box>
                  {riderObjWithMaxCreated.code}
                </Typography>
              </Box>
              <Rating
                name="read-only"
                size="small"
                value={2.5}
                precision={0.5}
                readOnly
              />
            </Box>
            {byStatusData.map((item, index) => {
              let statusStyle = {}; // Define an empty object to hold style properties

              if (index === 0) {
                // Apply styles based on the status
                if (item.status === "completed") {
                  statusStyle = {
                    marginLeft: "10px",
                    borderRadius: "18px",
                    padding: "5px",
                    background: "#A5E4B6",
                    color: "#04562A",
                    padding: "5px",
                    fontSize: "16px", // Example: Green for completed
                  };
                } else if (item.status === "cancelled") {
                  statusStyle = {
                    marginLeft: "10px",
                    borderRadius: "18px",
                    padding: "5px",
                    color: "#BB193B",
                    backgroundColor: " #F3C0C7",
                    padding: "5px",
                    fontSize: "16px", // Example: Red for cancelled
                  };
                } else if (item.status === "requested") {
                  statusStyle = {
                    marginLeft: "10px",
                    borderRadius: "18px",
                    padding: "5px",
                    color: "#8B5C0E",
                    backgroundColor: "#F7E4BA",
                    padding: "5px",
                    fontSize: "16px", // Example: Blue for requested
                  };
                } else if (item.status === "accepted") {
                  statusStyle = {
                    marginLeft: "10px",
                    borderRadius: "18px",
                    padding: "5px",
                    background: "#A5E4B6",
                    color: "#04562A",
                    padding: "5px",
                    fontSize: "16px", // Example: Yellow for accepted
                  };
                }

                return (
                  <Typography
                    key={item.id}
                    style={statusStyle}
                    className="customStatus"
                  >
                    {item.status}
                  </Typography>
                );
              }

              return null;
            })}
          </Box>
          <Box style={{ display: "flex", gap: "8px", marginLeft: "auto" }}>
            <Box
              style={{
                height: "37px",
                width: "37px",
                borderRadius: "50%",
                background: "#E3552E",
                cursor: "pointer",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => openRiderDetails()}
            >
              <img
                src={RiderImage}
                alt=""
                style={{ color: "#E3552E", height: "60%", width: "60%" }}
              />
            </Box>
            <Box
              style={{
                height: "37px",
                width: "37px",
                borderRadius: "50%",
                background: "#E3552E",
                cursor: "pointer",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
              onClick={()=>copyMobileNo(userMobile)}
                src={m_circle}
                alt=""
                style={{ position: "absolute", height: "50%", width: "50%" }}
              />
            </Box>
            <Box
              style={{
                height: "37px",
                width: "37px",
                borderRadius: "50%",
                background: "#E3552E",
                cursor: "pointer",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={c_circle}
                alt=""
                style={{ position: "absolute", height: "50%", width: "50%" }}
              />
            </Box>
          </Box>
        </Box>
      );
    } else return (
      <Box
      sx={{display:"flex",alignItems:"center",gap:"10px",height:"fit-content"}}
      >
        <img src={ninja_head} height={70} width={70} style={{transform:"rotate(180deg)"}}/>
      <Typography sx={{fontSize:"20px"}}>
        Please assign a rider for this order
      </Typography>
      </Box>
      
    )
  };

  const getUserInfo = () => {
    if (userObjFromBooking) {
      return (
        <Box key={userObjFromBooking.user?.uId}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt={userName}
                src={userAvatar}
                style={{ height: "55px", width: "55px", marginRight: "9px" }}
              />
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {userName || ""}
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {bookingNumber || ""}
                </Typography>
                <Typography
                  style={{
                    fontSize: 10,
                    fontWeight: 600,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {userMobile || ""}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  }}
                >
                  {ConvertDt(creatingTime)} | &nbsp;{Convert(creatingTime)}
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", gap: "5px", marginLeft: "auto" }}>
              <Box style={{ paddingTop: "12px", fontSize: "14px" }}>
                <Typography>
                  <strong>{totaldistance || ""} km.</strong>
                </Typography>
              </Box>
              <Box
                style={{
                  height: "37px",
                  width: "37px",
                  borderRadius: "50%",
                  background: "#E3552E",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => openPay()}
              >
                <img
                  src={bill}
                  alt=""
                  style={{ position: "absolute", height: "60%", width: "60%" }}
                />
              </Box>
              <Box
              onClick={()=>copyMobileNo(userMobile)}
                style={{
                  height: "37px",
                  width: "37px",
                  borderRadius: "50%",
                  background: "#E3552E",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={m_circle}
                  alt=""
                  style={{ position: "absolute", height: "50%", width: "50%" }}
                />
              </Box>
              <Box
                style={{
                  height: "37px",
                  width: "37px",
                  borderRadius: "50%",
                  background: "#E3552E",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={c_circle}
                  alt=""
                  style={{ position: "absolute", height: "50%", width: "50%" }}
                />
              </Box>
            </Box>
          </Box>
          {from.packageTypeObj !== null ? (
            <Typography
              style={{
                color: "#141414",
                fontSize: "13px",
                fontWeight: "400",
                marginLeft: "60px",
              }}
            >
              PACKAGE TYPE :{" "}
              <span
                style={{
                  color: "#141414",
                  fontSize: "13px",
                  fontWeight: "700",
                }}
              >
                {packageType || ""}
              </span>
            </Typography>
          ) : (
            <Typography
              style={{
                color: "#141414",
                fontSize: "13px",
                fontWeight: "400",
                marginLeft: "60px",
              }}
            >
              PACKAGE TYPE :{" "}
              <span
                style={{
                  color: "#141414",
                  fontSize: "15px",
                  fontWeight: "700",
                }}
              >
                Default
              </span>
            </Typography>
          )}
          <Typography
            style={{
              color: "#141414",
              fontSize: "13px",
              fontWeight: "400",
              marginLeft: "60px",
            }}
          >
            CURRENT STATUS :
            <span
              style={{
                color: "#141414",
                fontSize: "13px",
                fontWeight: "700",
              }}
            >
              {" "}
              {bookingstageobj === "Drop"
                ? "Pickup is completed"
                : bookingstageobj === "Completed"
                ? "Package is delivered"
                : bookingstageobj === "Pending"
                ? "Pickup and drop has not started yet"
                : bookingstageobj === "Booked"
                ? "Pickup is going to start"
                : bookingstageobj === "Cancelled"
                ? "Booking is cancelled"
                : " "}
            </span>
          </Typography>
          <Box
            style={style.instructioncontainer}
            sx={{ overflowY: "scroll", height: "64px" }}
          >
            <Typography style={style.heading}>INSTRUCTIONS</Typography>
            <Box style={style.box}>
              <Typography style={style.textCustName}>
                {userMessage ? userMessage : "No Instrcutions for this booking"}
              </Typography>
            </Box>
          </Box>
          {/* <Box style={style.container}>
                        <Typography style={style.heading}>PICKUP DETAILS</Typography>
                        <Box style={style.box}>
                            <Typography style={style.textCustName}>{userObjFromBooking.pickupDestination?.name}</Typography>
                            <Box>
                                <Typography style={style.textSubhead}>Phone Number</Typography>
                                <Typography style={style.textHead}>{userObjFromBooking.pickupDestination?.mobile}</Typography>
                            </Box>
                            <Box>
                                <Typography style={style.textSubhead}>Pickup OTP</Typography>
                                <Typography style={style.textHead}>{userObjFromBooking.pickupTask?.otp}</Typography>
                            </Box>
                            <Box>
                                <img src={sendOtpIcon} alt="sendOpt" style={style.img} />
                            </Box>
                        </Box>
                    </Box>
                    <Box style={style.container}>
                        <Typography style={style.heading}>DROP DETAILS</Typography>
                        <Box style={style.box}>
                            <Typography style={style.textCustName}>{userObjFromBooking.dropDestination?.name}</Typography>
                            <Box>
                                <Typography style={style.textSubhead}>Phone Number</Typography>
                                <Typography style={style.textHead}>{userObjFromBooking.dropDestination?.mobile}</Typography>
                            </Box>
                            <Box>
                                <Typography style={style.textSubhead}>Drop OTP</Typography>
                                <Typography style={style.textHead}>{userObjFromBooking.dropTask?.otp}</Typography>
                            </Box>
                            <Box>
                                <img src={sendOtpIcon} alt="sendOpt" style={style.img} />
                            </Box>
                        </Box>
                    </Box> */}
        </Box>
      );
    } else {
      return (
        <div>
          <h5>Loading...</h5>
        </div>
      );
    }
  };

  return isLoaded ? (
    <ThemeProvider>
      <Page title="Bookings">
        <Box padding="0 17px" style={{ marginBottom:"30px"}}>
          <Box sx={{ width: "100%", marginBottom: "7px" }}>
            <Box
              style={{
                display: "flex",
                marginBottom: "27px",
                marginTop: "20px",
              }}
            >
              <img
                src={larrow}
                onClick={handleOnClick}
                height={20}
                style={{
                  marginRight: "10px",
                  marginTop: "7px",
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
              />
              <Typography style={{ fontSize: "25px" }}>
                BOOKING DETAILS
              </Typography>
              <Box style={{ marginLeft: "auto", display: "flex" }}>
                <img
                  src={ArrowLeft}
                  alt=""
                  style={{ cursor: "pointer", height: "45px", width: "45px" }}
                  onClick={previousData}
                />
                <img
                  src={ArrowRight}
                  alt=""
                  onClick={nextData}
                  style={{ cursor: "pointer", height: "45px", width: "45px" }}
                />
              </Box>
            </Box>

            <Box style={{ padding: "0px 1px 0 15px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={4}>
                  <Box>
                    <Box
                      style={{
                        right: "0",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {from.packageTypeObj !== null ? (
                        <img
                          src={packageIcon}
                          alt=""
                          style={{
                            height: "55px",
                            width: "55px",
                            marginTop: "-40px",
                            marginRight: "-10px",
                            zIndex: "2",
                            background: "white",
                            borderRadius: "50%",
                            padding: "8px",
                            position: "absolute",
                            top: "",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>

                    <BookingDetailsTab
                      userName={userName}
                      userAvatar={userAvatar}
                      bookingNumber={bookingNumber}
                      userMobile={userMobile}
                      totaldistance={totaldistance}
                      packageType={packageType}
                      bookingstageobj={bookingstageobj}
                      userObjFromBooking={userObjFromBooking}
                      creatingTime={creatingTime}
                      distance={distance}
                      couponId={couponId}
                      TotalFeeDisc={TotalFeeDisc}
                      TotalFeeGst={TotalFeeGst}
                      TotalDiscount={TotalDiscount}
                      scheduleTime={scheduleTime}
                      currentDate={currentDate}
                      yesterday={yesterday}
                      tomorrow={tomorrow}
                      userMessage={userMessage}
                      amount={amount}
                      packageIcon={packageIcon}
                      activeId={activeBookingId}
                      landMark={landMark}
                      landMark1={landMark1}
                      desAddress={desAddress}
                      desAddress1={desAddress1}
                      desMobile={desMobile}
                      desMobile1={desMobile1}
                      desName={desName}
                      desName1={desName1}
                      longitude={longitude}
                      longitude1={longitude1}
                      latitude={latitude}
                      latitude1={latitude1}
                    />
                  </Box>

                  <Box style={{ background: "white", marginTop: "20px" }}>
                    {!details ? (
                      <>
                        <Box
                          style={{
                            minHeight: "250px",
                            maxHeight: "250px",
                            overflow: "auto",
                          }}
                        >
                          <Box
                            style={{
                              padding: "10px 4px 4px",
                              display: "flex",
                              alignItems: "center",
                              borderBottom: "1px solid #E04E2C",
                            }}
                          >
                            <img
                              src={larrow}
                              onClick={hideRiderDetails}
                              height={20}
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                            />

                            <Typography
                              style={{
                                color: "#030303",
                                fontWeight: 400,
                                background: "white",
                                fontSize: "15px",
                                paddingBottom: "2px",
                              }}
                            >
                              RIDER STATUS DETAILS
                            </Typography>
                          </Box>
                          <Box
                            styel={{
                              minHeight: "240px",
                              maxHeight: "240px",
                              overflow: "auto",
                            }}
                          >
                            {riderPersonalDetail()}
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Box>
                          <Box
                            style={{
                              background: "white",
                            }}
                          >
                            <Typography
                              style={{
                                borderBottom: "1px solid #E04E2C",
                                margin: "0 26px",
                                background: "white",
                                padding: "15px 0 5px 0",
                                fontFamily: "Inter,sans-serif",
                              }}
                            >
                              RIDER DETAILS
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              padding: "24px 26px 14px",
                              display: "flex",
                              flexDirection: "column",
                              maxHeight: "240px",
                              boxShadow: "0 0 6px 0px #e6e6e6",
                              position: "relative",
                              background: "white",
                              boxShadow:
                                "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                            }}
                          >
                            <Box
                              className="hidescroll"
                              style={{ flex: "1", overflow: "auto" }}
                            >
                              {riderList === 0
                                ? "No riders assigned"
                                : riderDetailing()}
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      background: "white",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        padding: "0 30px",
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="NEXT DESTINATION" {...a11yProps(0)} />
                        <Tab label="MESSAGE" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <Box sx={{ display: "flex", height: "100%" }}>
                        <Box
                          style={{
                            paddingRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            className="rotation"
                            style={{
                              padding: "0px",
                              marginTop: "0px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Total&nbsp;Distance:&nbsp;{distance || ""}&nbsp;km
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              minHeight: "15px",
                              width: "15px",
                              background: "green",
                              borderRadius: "50%",
                              paddingBottom: "-10px",
                            }}
                          ></Box>

                          <Slider
                            sx={{
                              '& input[type="range"]': {
                                WebkitAppearance: "slider-vertical",
                              },
                              ".MuiSlider-rail": {
                                backgroundColor: "#E3552E",
                              },
                              ".MuiSlider-track": {
                                backgroundColor: "#808080",
                              },
                              ".MuiSlider-thumb": {
                                width: "50px",
                                height: "50px",
                                background: `url(${ninja_head}) no-repeat center / contain`,
                              },
                              ".MuiSlider-mark": {
                                color: "#E3552E",
                                borderRadius: "50%",
                              },
                              "	.MuiSlider-markLabel": {
                                transform: "rotate(180deg)",
                                padding: "3px",
                                fontSize: "12px",
                                borderRadius: "15px",
                                minWidth: "40px",
                                textAlign: "center",
                                left: "-11.5px",
                              },
                              '& .MuiSlider-markLabel[data-index="2"]': {
                                background: "#FCEEEA",
                                color: "#E3552E",
                              },
                              '& .MuiSlider-markLabel[data-index="1"]': {
                                background: "#EBEBEB",
                                color: "black",
                              },
                              ".MuiSlider-markLabel:empty": {
                                display: "none",
                              },
                              ".MuiSlider-thumb:before": {
                                // content: `'On Time'`,
                                right: "70px",
                                transform: "rotate(180deg)",
                                width: "115px",
                                boxShadow: "none",
                                // color: '#E3552E',
                                // background: '#FCEEEA',
                                padding: "16px",
                                borderRadius: "40px",
                                textAlign: "center",
                                height: "auto",
                              },
                              transform: "rotate(180deg)",
                              borderRadius: "0px",
                              marginRight: "0px",
                            }}
                            orientation="vertical"
                            // step={50}
                            value={55}
                            getAriaValueText={valuetext}
                            marks={marks}
                            style={{ height: "300px" }}
                          />

                          <Box
                            sx={{
                              height: "15px",
                              width: "15px",
                              background: "#002236",
                            }}
                          ></Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              flex: "1",
                              paddingLeft: "30px",
                              height: "calc(100vh - 520px)",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            {userObjFromBooking &&
                              userObjFromBooking.tasksList.map(
                                (task, index) => {
                                  return (
                                    <Box>
                                      {(task.task?.type === "pickup" ||
                                        index == 0) && (
                                        <Box>
                                          <Box
                                            key={index}
                                            style={
                                              task.task?.status === "completed"
                                                ? {
                                                    display: "flex",
                                                    alignItems: "start",
                                                    background: "#DEFFED",
                                                    borderRadius: "15px",
                                                    padding: "10px",
                                                    width: "330px",
                                                  }
                                                : {
                                                    display: "flex",
                                                    alignItems: "start",
                                                    background: "#F9F9F9",
                                                    borderRadius: "15px",
                                                    padding: "10px",
                                                    width: "330px",
                                                  }
                                            }
                                          >
                                            {task.task?.status ===
                                            "completed" ? (
                                              <Box>
                                                <img
                                                  src={correct}
                                                  alt=""
                                                  style={{
                                                    paddingTop: "27px",
                                                    paddingRight: "20px",
                                                  }}
                                                />
                                                <Typography
                                                  sx={{
                                                    marginRight: "20px",
                                                    marginTop: "15px",
                                                  }}
                                                  style={style.textHead}
                                                >
                                                  Done
                                                </Typography>
                                              </Box>
                                            ) : (
                                              <img
                                                src={HourglassHigh}
                                                alt=""
                                                style={{
                                                  paddingTop: "37px",
                                                  paddingRight: "20px",
                                                }}
                                              />
                                            )}

                                            <Box>
                                              <Typography
                                                style={{
                                                  color: "#002236",
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {" "}
                                                {task?.destination?.name?.replace(
                                                  /(\w)(\w*)/g,
                                                  (_, g1, g2) =>
                                                    g1.toUpperCase() +
                                                    g2.toLowerCase()
                                                ) || ""}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color: "#9D9E9E",
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {task?.destination?.mobile}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color: "#E3552E",
                                                  fontSize: "12px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                {" "}
                                                {task.task?.type === "pickup" ||
                                                index == 0
                                                  ? "Pickup"
                                                  : "Drop"}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color: "#002236",
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {" "}
                                                {task?.destination?.landMark?.replace(
                                                  /(\w)(\w*)/g,
                                                  (_, g1, g2) =>
                                                    g1.toUpperCase() +
                                                    g2.toLowerCase()
                                                ) || ""}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color: "#9D9E9E",
                                                  fontSize: "12px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                {" "}
                                                {task?.destination?.address?.replace(
                                                  /(\w)(\w*)/g,
                                                  (_, g1, g2) =>
                                                    g1.toUpperCase() +
                                                    g2.toLowerCase()
                                                ) || ""}
                                              </Typography>
                                            </Box>
                                            <Box
                                              style={{
                                                display: "flex",
                                                gap: "8px",
                                                marginLeft: "auto",
                                              }}
                                            >
                                              <Box
                                              onClick={()=>copyMobileNo(task?.destination?.mobile)}
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  borderRadius: "50%",
                                                  background: "#E3552E",
                                                  cursor: "pointer",
                                                  position: "relative",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  src={m_circle}
                                                  alt=""
                                                  style={{
                                                    position: "absolute",
                                                    height: "50%",
                                                    width: "50%",
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}

                                      {(task.task?.type === "drop" ||
                                        index == 1) && (
                                        <Box>
                                          <Box
                                            key={index}
                                            style={
                                              task.task?.status === "completed"
                                                ? {
                                                    display: "flex",
                                                    alignItems: "start",
                                                    background: "#DEFFED",
                                                    borderRadius: "15px",
                                                    padding: "10px",
                                                    width: "330px",
                                                    marginTop: "90px",
                                                  }
                                                : {
                                                    display: "flex",
                                                    alignItems: "start",
                                                    background: "#F9F9F9",
                                                    borderRadius: "15px",
                                                    padding: "10px",
                                                    width: "330px",
                                                    marginTop: "90px",
                                                  }
                                            }
                                          >
                                            <Box>
                                              {task.task?.status ===
                                              "completed" ? (
                                                <>
                                                  <img
                                                    src={correct}
                                                    alt=""
                                                    style={{
                                                      paddingTop: "27px",
                                                      paddingRight: "20px",
                                                    }}
                                                  />
                                                  <Typography
                                                    sx={{
                                                      marginRight: "20px",
                                                      marginTop: "15px",
                                                    }}
                                                    style={style.textHead}
                                                  >
                                                    Done
                                                  </Typography>
                                                </>
                                              ) : (
                                                <img
                                                  src={HourglassHigh}
                                                  alt=""
                                                  style={{
                                                    paddingTop: "37px",
                                                    paddingRight: "20px",
                                                  }}
                                                />
                                              )}
                                            </Box>
                                            <Box>
                                              <Typography
                                                style={{
                                                  color: "#002236",
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {" "}
                                                {task?.destination?.name?.replace(
                                                  /(\w)(\w*)/g,
                                                  (_, g1, g2) =>
                                                    g1.toUpperCase() +
                                                    g2.toLowerCase()
                                                ) || ""}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color: "#9D9E9E",
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {task?.destination?.mobile}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color: "#E3552E",
                                                  fontSize: "12px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                {"Drop"}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color: "#002236",
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {" "}
                                                {task?.destination?.landMark?.replace(
                                                  /(\w)(\w*)/g,
                                                  (_, g1, g2) =>
                                                    g1.toUpperCase() +
                                                    g2.toLowerCase()
                                                ) || ""}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color: "#9D9E9E",
                                                  fontSize: "12px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                {" "}
                                                {task?.destination?.address?.replace(
                                                  /(\w)(\w*)/g,
                                                  (_, g1, g2) =>
                                                    g1.toUpperCase() +
                                                    g2.toLowerCase()
                                                ) || ""}
                                              </Typography>
                                            </Box>
                                            <Box
                                              style={{
                                                display: "flex",
                                                gap: "8px",
                                                marginLeft: "auto",
                                              }}
                                            >
                                              <Box
                                                onClick={()=>copyMobileNo(task?.destination?.mobile)}
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  borderRadius: "50%",
                                                  background: "#E3552E",
                                                  cursor: "pointer",
                                                  position: "relative",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  src={m_circle}
                                                  alt=""
                                                  style={{
                                                    position: "absolute",
                                                    height: "50%",
                                                    width: "50%",
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                  );
                                }
                              )}
                          </Box>
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel
                      value={value}
                      index={1}
                      style={{ padding: "0 0 63px 0" }}
                    >
                      Coming Soon
                    </TabPanel>
                  </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      background: "white",
                      width: "100%",
                    }}
                  >
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={7}
                      onLoad={onMapLoad}
                    ></GoogleMap>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            {form ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    height: "500px",
                    backgroundColor: "white",
                    overflow: "hidden",
                    marginTop: "50px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      marginTop: "15px",
                      borderBottom: "1px solid red",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#030303",
                        fontWeight: 400,
                        background: "white",
                        fontSize: "18px",
                        paddingBottom: "3px",
                        display: "flex",
                        fontSize: "25px",
                        paddingTop: "10px",
                        spacing: "3px",
                      }}
                    >
                      <img
                        src={larrow}
                        onClick={hidehandelOnClickPlus}
                        height={20}
                        style={{
                          marginRight: "10px",
                          marginTop: "3px",
                          fontWeight: "400",
                          cursor: "pointer",
                          height: "30px",
                          width: "30px",
                        }}
                      />
                      ADD TRANSACTION
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        width: "600px",
                        marginTop: "40px",
                      }}
                    >
                      <Box
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          style={{
                            display: "block",
                            color: "black",
                            fontWeight: "400px",
                            fontSize: "20px",
                          }}
                        >
                          Transaction ID<sup style={{ color: "red" }}>⋆</sup>
                        </Typography>

                        <input
                          type="text"
                          name="name"
                          placeholder="Enter the Transaction Id"
                          value={name}
                          onChange={handleNameChange}
                          style={{
                            width: "100%",
                            color: "black",
                            padding: "12px",
                            borderColor: "green",
                            borderRadius: "2px",
                            boxSizing: "border-box",
                            marginTop: "2px",
                            marginBottom: "2px",
                            fontSize: "18px",
                            cursor: "pointer",
                            "::placeholder": {
                              color: "#000",
                              fontStyle: "italic",
                              fontSize: "15px",
                            },
                            resize: "vertical",
                          }}
                        />
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          Instruction: You will get the transction Id from
                          customer and copy here.
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          style={{
                            display: "block",
                            color: "black",
                            fontWeight: "400px",
                            fontSize: "20px",
                          }}
                        >
                          Amount<sup style={{ color: "red" }}>⋆</sup>
                        </Typography>
                        <input
                          type="number"
                          id="price"
                          name="price"
                          placeholder="₹"
                          value={price}
                          onChange={handlePriceChange}
                          style={{
                            width: "100%",
                            color: "black",
                            padding: "12px",
                            borderColor: "green",
                            borderRadius: "4px",
                            boxSizing: "border-box",
                            marginTop: "6px",
                            marginBottom: "16px",
                            resize: "vertical",
                            fontSize: "18px",
                            cursor: "pointer",
                            "::placeholder": {
                              color: "#000",
                              fontSize: "15px",
                            },
                          }}
                        />
                      </Box>
                      <Box
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          class="button-add"
                          role="button"
                          onClick={addTransactions}
                          disable={isButtonDisabled}
                          style={{
                            fontWeight: "600",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "18px",
                            padding: "0.6em 1em 0.6em 0.9em",
                            color: "white",
                            background: "#ad5389",
                            background:
                              "linear-gradient(0deg, rgba(20,167,62,1) 0%, rgba(102,247,113,1) 100%)",
                            border: "none",
                            boxShadow: "0 0.7em 1.5em -0.5em #14a73e98",
                            borderRadius: "7px",
                            cursor: "pointer",
                            marginTop: "8px",
                            cursor: isButtonDisabled
                              ? "not-allowed"
                              : "pointer",
                            opacity: isButtonDisabled ? "0.6" : "1",
                          }}
                        >
                          ADD
                        </button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                {" "}
                <Box sx={{ width: "100%" }}>
                  <Typography
                    style={{
                      color: "#030303",
                      fontSize: "24px",
                      fontWeight: "300",
                      borderBottom: "1px solid red",
                      marginBottom: "12px",
                      marginTop: "35px",
                      display: "flex",
                    }}
                  >
                    TRANSACTION DETAILS&nbsp;
                    <Box
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleOnClickPlus()}
                    >
                      <img
                        src={plusSquare}
                        alt=""
                        style={{
                          marginBottom: "8px",
                          marginRight: "10px",
                          marginTop: "0px",
                          width: "35px",
                          height: "35px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Typography>
                  <Card>
                    <Scrollbar>
                      <TableContainer
                        sx={{
                          minWidth: 800,
                          height: "500px",
                          padding: "7px 0",
                          overflow: "hidden",
                         
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableCell style={{ textAlign: "left" }}>
                              Transaction Id
                            </TableCell>

                            <TableCell style={{ textAlign: "left" }}>
                              Amount
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              Date & Time
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              Type
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              Status
                            </TableCell>
                          </TableHead>
                          <TableBody>
                            {transactionDetails?.map((row) => {
                              return (
                                <TableRow>
                                  <TableCell style={{ textAlign: "left" }}>
                                    {row?.transactionId}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row?.amount}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "left" }}>
                                    {Convert(row?.created)}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row?.type}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "right" }}>
                                    {" "}
                                    {row.isSuccessful ? (
                                      <Chip
                                        style={{
                                          background: "#A5E4B6",
                                          color: "#04562A",
                                          marginLeft: "auto",
                                        }}
                                        label={row?.status}
                                      />
                                    ) : (
                                      <Chip
                                        style={{
                                          background: "#F3C0C7",
                                          color: "#BB193B",
                                          marginLeft: "auto",
                                        }}
                                        label={row?.status}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            {isNotFounds && (
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{ py: 3 }}
                                colSpan={8}
                              >
                                No Transactions found.
                              </TableCell>
                            </TableRow>
                          )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                  </Card>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Page>
    </ThemeProvider>
  ) : (
    <></>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter(
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export function ThreedotMenu({ row, deleteBooking }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={() => deleteBooking(row?.id)}>
          <Iconify icon={"eva:trash-2-outline"} />
          Delete
        </MenuItem>

        {/* <Button component={RouterLink} to={`${PATH_DASHBOARD.group.editById}/${row?.id}`}>
          <Iconify icon={'eva:edit-fill'} />
          Edit

        </Button> */}
      </MenuPopover>
    </>
  );
}
