import { Suspense, lazy, useContext } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import RateList from "../pages/rates/rateList";
import NewRate from "../pages/rates/newRate";
import NewUsers from "src/pages/users/newUsers";
import CouponList from "../pages/coupons/couponList";
import NewCoupon from "../pages/coupons/newCoupon";
import GroupList from "../pages/users/groupList";
import NewGroup from "../pages/users/newGroup";
import ReasonList from "../pages/reasons/reasonList";
import NewReason from "../pages/reasons/newReason";
import AllUsers from "../pages/users/allUsers";
import AllRiders from "../pages/riders/allRiders";
import NewRider from "../pages/riders/newRider";
import NotificationList from "../pages/notifications/notificationList";
import NewNotification from "../pages/notifications/newNotification";
import NewTransaction from "src/pages/payments/newTransaction";
import TransactionList from "src/pages/payments/transactionList";
import BookingList from "src/pages/bookings/bookingList";
import BusinessList from "src/pages/business/BusinessList";
import BusinessDetails from "src/pages/business/BusinessDetails";
import Chat from "src/pages/chat/chat";
import Cities from "src/pages/cities/cities";
import PackageTypes from "src/pages/packageTypes/packageTypes";
// import MainLayout from '../layouts/main';
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import RiderDetails from "src/pages/riders/riderDetails";
import UserDetails from "src/pages/users/userDetails"
// guards
import AuthGuard from "../guards/AuthGuard";

// config
import { PATH_AFTER_LOGIN } from "../utils/config";
// components
import LoadingScreen from "../components/LoadingScreen";
import BookingDetailsgs from "src/pages/bookings/bookingDetails";
import Campaigns from "src/pages/campaigns/campaigns";
import NewCampaign from "src/pages/campaigns/newCampaign";
import { MainContext } from "src/contexts/context";
import NewCity from "src/pages/cities/newCity";
import NewPackage from "src/pages/packageTypes/newPackage";

console.log(
  {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_APP_ID}`,
    measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`,
  },
  "$New$"
);

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { type } = useContext(MainContext);
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        {
          path: "register",
          element: (
            <AuthGuard>
              <Register />
            </AuthGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "dashboard", element: <GeneralApp /> },
        {
          path: "activity",
          element: (
            <AuthGuard>
              <GeneralBooking />
            </AuthGuard>
          ),
        },

        {
          path: "reasons",
          children: [
            { element: <ReasonList />, index: true },
            { path: "", element: <ReasonList /> },

            { path: "add", element: <NewReason /> },
          ],
        },
        {
          path: "notifications",
          children: [
            { element: <NotificationList />, index: true },
            { path: "", element: <NotificationList /> },

            { path: "add", element: <NewNotification /> },
            { path: "edit/:id", element: <NewNotification /> },
          ],
        },
        {
          path: "campaigns",
          children: [
            { element: <Campaigns />, index: true },
            { path: "", element: <Campaigns /> },

            { path: "add", element: <NewCampaign /> },
          ],
        },
        {
          path: "settings",
          children: [
            { element: <RateList />, index: true },
            { path: "", element: <RateList /> },

            { path: "add", element: <NewRate /> },
            { path: "edit/:id", element: <NewRate /> },
          ],
        },
        {
          path: "coupons",
          children: [
            { element: <CouponList />, index: true },
            { path: "", element: <CouponList /> },
            { path: "add", element: <NewCoupon /> },
            { path: "edit/:id", element: <NewCoupon /> },
          ],
        },
        {
          path: "groups",
          children: [
            { element: <GroupList />, index: true },
            { path: "", element: <GroupList /> },

            { path: "add", element: <NewGroup /> },
            { path: "edit/:id", element: <NewGroup /> },
          ],
        },
        {
          path: "users",
          children: [
            { element: <AllUsers />, index: true },
            { path: "", element: <AllUsers /> },
            { path: "add", element: <NewUsers /> },
            { path: "edit/:id", element: <NewUsers /> },
            {path: "details", element: <UserDetails/>}
          ],
        },

        {
          path: "riders",
          children: [
            { element: <AllRiders />, index: true },
            { path: "", element: <AllRiders /> },
            { path: "add", element: <NewRider /> },
            { path: "edit/:id", element: <NewRider /> },
            { path: "details", element: <RiderDetails /> },
          ],
        },

        {
          path: "transactions",
          children: [
            { element: <TransactionList />, index: true },
            { path: "", element: <TransactionList /> },
            { path: "add", element: <NewTransaction /> },
            { path: "edit/:id", element: <NewTransaction /> },
          ],
        },
        {
          path: "bookings",
          children: [
            { element: <BookingList />, index: true },
            { path: "", element: <BookingList /> },
            { path: "details", element: <BookingDetailsgs /> },
          ],
        },
        {
          path: "business",
          children: [
            { element: <BusinessList />, index: true },
            { path: "", element: <BusinessList /> },
            { path: "details", element: <BusinessDetails /> },
          ],
        },

        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "add", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },

        {
          path: "cities",
          children: [
            { element: <Cities />, index: true },
            {
              path: "",
              element: (
                <AuthGuard>
                  <Cities />
                </AuthGuard>
              ),
            },
            { path: "add", element: <NewCity /> },
            { path: "edit/:id", element: <NewCity /> },
          ],
        },
        {
          path: "packages",
          children: [
            { element: <PackageTypes />, index: true },
            {
              path: "",
              element: (
                <AuthGuard>
                  <PackageTypes />
                </AuthGuard>
              ),
            },
            { path: "add", element: <NewPackage /> },
            { path: "edit/:id", element: <NewPackage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <Login />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
// Dashboard
const GeneralApp = Loadable(lazy(() => import("../pages/dashboard/dashboard")));
const GeneralBooking = Loadable(
  lazy(() => import("../pages/activities/activities"))
);

// Main

const Pricing = Loadable(lazy(() => import("../pages/pricings/pricing")));
const Payment = Loadable(lazy(() => import("../pages/payments/payment")));
const Page500 = Loadable(lazy(() => import("../pages/common/page500")));
const NotFound = Loadable(lazy(() => import("../pages/common/page404")));
