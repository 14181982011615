import { Box,Button, Divider, Typography, Stack, Dialog } from "@mui/material";
// components
import Iconify from "./Iconify";
// assets
import { SeoIllustration } from "../assets";


export default function CheckoutOrderComplete({ ...other }) {
  if (other.open)
    return (
      <Dialog open={other.open} onClose={other.onClose} fullWidth aria-labelledby="form-dialog-title">
        <Box sx={{ p: 4, maxWidth: "100%",width:"100%", margin: "auto" }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" paragraph>
              {other?.title}
            </Typography>

            <SeoIllustration sx={{ height: 260, my: 3 }} />

            <Typography align="center" paragraph>
              {other?.subTitle} &nbsp;
              {/* <Link href="#">01dc1370-3df6-11eb-b378-0242ac130002</Link> */}
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            justifyContent="space-between"
            spacing={2}
          >
            <Button
              color="inherit"
              onClick={other.onClose}
              startIcon={<Iconify icon={"eva:arrow-ios-back-fill"} />}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={other.handleSubmit}>
              Yes
            </Button>
          </Stack>
        </Box>
      </Dialog>
    );
  else return null;
}
