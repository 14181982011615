import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tab,
  Tabs,
  Box,
  CircularProgress,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
// sections
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import BookingList from "../bookings/bookingList";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions,reset } from "src/redux/slices/GetTransactions";
import LoadingScreen from "src/components/LoadingScreen";

// tab start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// tab end

function Innerpage(dataaa) {
  const { children, c_Data, index, ...other } = dataaa;

  return (
    <div
      role="Innerpage"
      hidden={c_Data !== index}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      {...other}
    >
      {c_Data === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TABLE_HEAD = [
  { id: "name", label: "Transaction Id", alignRight: false },
  { id: "company", label: "Booking Number", alignRight: false },
  { id: "role", label: "Type", alignRight: false },
  { id: "isVerified", label: "Amount", alignRight: true },
  { id: "status", label: "Date&Time", alignRight: true },
  { id: "Action", label: "Status", alignRight: true },
];

// ----------------------------------------------------------------------

export default function TransactionList() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getTransactions);
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [value, setValue] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [c_Data, setc_Data] = useState(0);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = transactionList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = transactionList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setTransactionList(deleteUsers);
  };

  const isNotFound = transactionList?.length == 0 ? true : false;
  useEffect(() => {
    dispatch(reset([]));
  }, []);
  
  useEffect(() => {
    if (filterValue?.length > 0 && searchValue?.length >= 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);

  useEffect(() => {
    filterSearch();
  }, [rowsPerPage, page]);

  useEffect(() => {
    getTransaction();
  }, [state]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.cityId = localStorage.getItem("manager_city");
      payload.type = "all";
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;

      await axios
        .post("/transactions/get/all", payload)
        .then((res) => {
          console.log("all transactions payload........", payload, res.data);
          setLoading(false);
          setTransactionList(res?.data?.result || []);
          setTotalItems(res?.data?.pagination?.totalItems || 0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getTransaction = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchTransactions(payload));
      } else {
        setTransactionList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const Convert = (dt) => {
    if (!dt) return "";
    let m = new Date(dt).getHours();
    let n = m > 12 ? "PM" : "AM";
    const dd = `${new Date(dt).getDate()} ${new Date(dt).toLocaleString(
      "en-US",
      { month: "long" }
    )} ${new Date(dt).getFullYear()} ${new Date(dt).getHours()}:${new Date(
      dt
    ).getMinutes()} ${n}`;
    return dd;
  };

  return (
    <ThemeProvider>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading&& initialLoad  && <LoadingScreen />}
      <Page title="Transactions">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="BOOKINGS"
                  {...a11yProps(0)}
                  style={{
                    color: "#030303",
                    fontSize: "32px",
                    fontWeight: "300",
                  }}
                />
                <Tab
                  label="TRANSACTIONS"
                  {...a11yProps(1)}
                  style={{
                    color: "#030303",
                    fontSize: "32px",
                    fontWeight: "300",
                  }}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} className="tabPanel">
              <BookingList />
            </TabPanel>

            <TabPanel value={value} index={1} className="tabPanel">
              <Box sx={{ width: "100%" }}>
                <Card sx={{ borderRadius: "0" }}>
                  <Innerpage value={c_Data} index={0}></Innerpage>
                  <Innerpage value={c_Data} index={1}>
                    Item Two
                  </Innerpage>
                  <Innerpage value={c_Data} index={2}>
                    Item Three
                  </Innerpage>
                  <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    onDeleteUsers={() => handleDeleteMultiUser(selected)}
                    setFilterValue={setFilterValue}
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    filterData={filterData}
                    isMonthRequired={true}
                  />

                  <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
                    <Scrollbar>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={transactionList.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {transactionList?.map((row) => {
                            const isItemSelected =
                              selected.indexOf(row.id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {row?.transactionId}
                                  </Typography>
                                </TableCell>
                                <TableCell>{row?.bookingNumber}</TableCell>
                                <TableCell>{row?.type}</TableCell>
                                <TableCell align="right">
                                  {row?.amount}
                                </TableCell>
                                <TableCell align="right">
                                  {Convert(row?.created)}
                                </TableCell>
                                <TableCell align="right">
                                  {row?.status}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {isNotFound && (
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{ py: 3 }}
                                colSpan={8}
                              >
                                No data found.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[50, 100, 250]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={(e, page) => {
                      setPage(page + 1);
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </Box>
            </TabPanel>
          </Box>
        </Container>
      </Page>
    </ThemeProvider>
  );
}
