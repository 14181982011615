import * as Yup from 'yup';
import { useCallback, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate,useLocation, useParams } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Grid, Card, Chip, Stack, Button, TextField, Typography, Autocomplete } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from "../../utils/axiosconfig";
// components
import { RHFSwitch, RHFEditor, FormProvider, RHFTextField, RHFUploadSingleFile, RHFRadioGroup, RHFSelect } from '../../components/hook-form';


export default function NewRate() {
  const navigate = useNavigate();
  const [cityId, setCityId] = useState("");
  const [basicDuration, setBasicDuration] = useState("");
  const [basicDistance, setBasicDistance] = useState("");
  const [basicRate, setBasicRate] = useState("");
  const [distanceRate, setDistanceRate] = useState("");
  const [durationRate, setDurationRate] = useState("");
  const [basicWaitingDuration, setBasicWaitingDuration] = useState("");
  const [waitingRate, setWaitingRate] = useState("");
  const [basicPay, setBasicPay] = useState("");
  const [distancePay, setDistancePay] = useState("");
  const [durationPay, setDurationPay] = useState("");
  const [specialPay, setSpecialPay] = useState("");
  const [cityList, setCityList] = useState([]);
const {id} = useParams();
const {pathname} = useLocation();
const isEdit = pathname.includes('edit');
  const GENDER_OPTION = ['false', 'true'];

  const theme = createTheme({
    palette: {
      primary: {
        main: '#e65100',
      },
    },
  });
  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;


  // const onSubmit = async () => {
  //   try {
  //     const payload = {};
  //     payload.cityId = cityId;
  //       payload.basicDuration = basicDuration;
  //       payload.basicDistance= basicDistance;
  //       payload.basicRate = basicRate;
  //       payload.distanceRate= distanceRate;
  //       payload.durationRate= durationRate;
  //       payload.basicWaitingDuration= basicWaitingDuration;
  //       payload.waitingRate= waitingRate;
  //       payload.basicPay= basicPay;
  //       payload.distancePay= distancePay;
  //       payload.durationPay= durationPay;
  //       payload.specialPay= specialPay;

  //     const res = await fetch(`https://us-central1-pickupninjaapp.cloudfunctions.net/app/api/rates/add`, {
  //       method: 'POST',
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(payload),
  //     });
  //     const result = await res.json();
  //     if (result) {
  //       reset();
  //       navigate(PATH_DASHBOARD.rate.rates);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


  const onSubmit = () => {
    if(!isEdit){
    const payload = {};
    payload.cityId = cityId;
    payload.basicDuration = basicDuration;
    payload.basicDistance = basicDistance;
    payload.basicRate = basicRate;
    payload.distanceRate = distanceRate;
    payload.durationRate = durationRate;
    payload.basicWaitingDuration = basicWaitingDuration;
    payload.waitingRate = waitingRate;
    payload.basicPay = basicPay;
    payload.distancePay = distancePay;
    payload.durationPay = durationPay;
    payload.specialPay = specialPay;
    axios.post('/rates/add', payload).then((res) => {
      console.log(res);
      navigate(PATH_DASHBOARD.rate.rates);
    }).catch((err) => {
      console.log(err);
    })
  }{

    const payload = {};
    payload.cityId = cityId;
    payload.basicDuration = basicDuration;
    payload.basicDistance = basicDistance;
    payload.basicRate = basicRate;
    payload.distanceRate = distanceRate;
    payload.durationRate = durationRate;
    payload.basicWaitingDuration = basicWaitingDuration;
    payload.waitingRate = waitingRate;
    payload.basicPay = basicPay;
    payload.distancePay = distancePay;
    payload.durationPay = durationPay;
    payload.specialPay = specialPay;
    axios.put(`/rates/update/${id}`, payload).then((res) => {
      console.log(res);
      navigate(PATH_DASHBOARD.rate.rates);
    }).catch((err) => {
      console.log(err);
    })


  }
  }

  useEffect(() => {
    getCity();
  }, []);

  const getCity = () => {
    axios.get("/cities/get").then((res) => {
      console.log(res.data);
      //  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
      setCityList(res.data.result);
    }).catch((err) => {
      console.log("error", err)
    })
  }


  return (
    <>
      <ThemeProvider theme={theme}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  {/* <RHFTextField name="title" label="city ID" value={cityId} onChange={(e) => setCityId(e.target.value)}/> */}

                  <RHFSelect name="city" label="City" placeholder="City" value={cityId} onChange={(e) => setCityId(e.target.value)} required>
                    <option value="" />
                    {cityList.map((option) => (
                      <option key={option.city} value={option.city}>
                        {option.city}
                      </option>
                    ))}
                  </RHFSelect>

                  <RHFTextField name="title" label="Basic Duration" value={basicDuration} onChange={(e) => setBasicDuration(e.target.value)} required />
                  <RHFTextField name="title" label="Basic Distance" value={basicDistance} onChange={(e) => setBasicDistance(e.target.value)} required />
                  <RHFTextField name="title" label="Basic Rate" value={basicRate} onChange={(e) => setBasicRate(e.target.value)} required />
                  <RHFTextField name="title" label="Distance Rate" value={distanceRate} onChange={(e) => setDistanceRate(e.target.value)} required />
                  <RHFTextField name="title" label="Duration Rate" value={durationRate} onChange={(e) => setDurationRate(e.target.value)} required />
                  <RHFTextField name="title" label="Basic Waiting Duration" value={basicWaitingDuration} onChange={(e) => setBasicWaitingDuration(e.target.value)} required />
                  <RHFTextField name="title" label="Waititng Rate" value={waitingRate} onChange={(e) => setWaitingRate(e.target.value)} required />
                  <RHFTextField name="title" label="Basic Pay" value={basicPay} onChange={(e) => setBasicPay(e.target.value)} required />
                  <RHFTextField name="title" label="DistancePay" value={distancePay} onChange={(e) => setDistancePay(e.target.value)} required />
                  <RHFTextField name="title" label="Duration Pay" value={durationPay} onChange={(e) => setDurationPay(e.target.value)} required />
                  <RHFTextField name="title" label="Special Pay" value={specialPay} onChange={(e) => setSpecialPay(e.target.value)} required />
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
                {/* <Button fullWidth color="inherit" variant="outlined" size="large" onClick={handleOpenPreview}>
                    Preview
                  </Button> */}
                <LoadingButton fullWidth type="submit" variant="contained" size="large" loading={isSubmitting} >
                  {!isEdit? "Create Rate":"Save Changes"}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>


      </ThemeProvider>
    </>
  );

}