import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import { TextField, Autocomplete } from '@mui/material';
import { BorderBottom, MicNone } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../../utils/axiosconfig';

const formulas = [
    { label: '(=) Equal to ', value: "=" },
    { label: '(<) LessThan', value: "<" },
    { label: '(>) GreaterThan', value: ">" },
    { label: '(<=) LessThan Equal to', value: "<=" },
    { label: '(=>) GreaterThan Equal to', value: "=>" },

];

const style = {
    position: 'absolute',
    top: '287px',
    right: '809px',
    width: '360px',
    backgroundColor: '#f5f5f5',
    border: '0px solid #c1c1c1',
    borderRadius: '10px',
};
const display = {
    display: 'none'
};
function Filter({ setOperator, setValues, values, setField, field, setIsFormula }) {
    const [open, setOpen] = useState(false);
    const [fieldList, setFieldList] = useState([]);
    const [Display, setDisplay] = useState(false);
    const handleOpen = () => { setOpen(!open) }
    const handleClose = () => { setOpen(false) }

    const activeBtn = () => {
        setDisplay(true)
    }
    const inactiveBtn = () => {
        setDisplay(false);
        setField("");
    }

    useEffect(() => {
        axios.get('/users/get/fields').then((res) => {
            setFieldList(res.data.result);
            console.log(res.data.result);
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const handleFormulaChange = (event, value) => {
        setOperator(value.value);
    };

    const clearFilter = () => {
        setOperator("");
        setValues("")
        setField("")
        inactiveBtn()
    }

    console.log(",.mnnghgj", open);
    return (
        <>
            <Box>
                <FilterListIcon style={{ position: 'relative', top: '0px', left: '13px',cursor:"pointer" }} onClick={() => handleOpen()} value={open} />

                {open === true ? <Box sx={{width:"500px"}}>
                   
                        <Typography sx={{marginBottom:"5px"}}>Field</Typography>
                      
                            <Box style={{ width: '324px', BorderBottom: '1px solid #7a7a7a' }} >
                                <TextField 
                                sx={{marginBottom:"5px",width:"500px"}}
                                className={Display ? 'inactive_input' : 'active_input'} 
                                placeholder='Enter Field' 
                                value={field} 
                                onChange={(e) => setField(e.target.value)}> 
                                </TextField>
                                <button 
                                className={Display ? 'AccordionDetails_activebutton' : 'AccordionDetails_inactivebutton'}>
                                {field}<CancelIcon onClick={() => inactiveBtn()} />
                                </button>
                            </Box>
                            <Box sx={{}}>
                                <Typography sx={{marginBottom:"5px"}}>Suggested fields</Typography>
                                {/* <button  className='AccordionDetails_button' onClick={()=>activeBtn()}>Name</button> */}
                                {
                                    fieldList.length > 0 ?
                                        (
                                            fieldList.map((items) => {
                                                return <button className='AccordionDetails_button' onClick={() => { activeBtn(); setField(items.label) }} >{items.label}</button>
                                            })
                                        ) : <></>
                                }
                            </Box>
                        
                    
                            <Typography sx={{margin:"5px 0px"}}>Operator</Typography>
                        
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={formulas}
                                sx={{ width: 300 }}
                                onChange={handleFormulaChange}
                                renderInput={(params) => <TextField {...params} label="Formula" />}
                                style={{ color: '#6e6e6e', fontSize: '14px', fontWeight: '500',marginBottom:"5px",width:"500px" }}
                            />
                 
                            <Typography sx={{margin:"5px 0px"}}>Value</Typography>
                       
                            {/* <input type="text" className="AccordionDetails_textField" placeholder='Enter Value To Filter' value={values} onChange={(e) => { setValues(e.target.value) }}></input> */}
                            <TextField
                            sx={{width:"500px",marginBottom:"5px"}} 
                            placeholder='Enter Value to filter'
                            value={values}
                            onChange={(e)=>{setValues(e.target.value)}}
                            >

                            </TextField>
                    
                   
                    <Box style={{ height: '43px', backgroundColor: '#ffff', display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <button className="filter_button" onClick={() => clearFilter()}>Clear</button>
                        </Box>
                        <Box>
                            <button className="filter_button" onClick={() => { handleClose() }}>Cancel</button>
                            <button className="filter_button_apply" onClick={() => { setIsFormula(true) }}>Apply</button>
                        </Box>
                    </Box>
                </Box> : <></>}
            </Box>
        </>
    )
}
export default Filter;


