import * as Yup from 'yup';
import { useCallback, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation, useParams, useHistory } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Grid, Card, Chip, Stack, Button, TextField, Typography, Autocomplete, IconButton, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from '../../utils/axiosconfig';
// components
import { RHFSwitch, RHFEditor, FormProvider, RHFTextField, RHFUploadSingleFile, RHFRadioGroup } from '../../components/hook-form';
import AllRiders from './allRiders';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// import axios from 'axios';

export default function NewRider() {
    const navigate = useNavigate();

    const { pathname } = useLocation();
    const [mobile, setMobile] = useState("");
    const [type, setType] = useState("");
    const [cityId, setCityId] = useState('');
    const [name, setName] = useState("");
    const [darkMode, setDarkMode] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const isEdit = pathname.includes('edit');
    const [ridersList, setRiderList] = useState([]);
    const [icon, setIcon] = useState();

    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    // const history = useHistory();
    const GENDER_OPTION = ['false', 'true'];

    const theme = createTheme({
        palette: {
            primary: {
                main: '#e65100',
            },
        },
    });

    const LabelStyle = styled(Typography)(({ theme }) => ({
        ...theme.typography.subtitle2,
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
    }));

    useEffect(() => {
        getRiders();
      }, []);


      const getRiders = () => {
        axios.get(`/riders/get/all`, {
          // config
        }).then((res) => {
          console.log(res.data);
    
          setRiderList(res.data.result);
    
        }).catch((err) => {
          console.log("error", err)
        })
      }
    const methods = useForm({
        // resolver: yupResolver(NewUserSchema),
        // defaultValues,
    });

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid },
    } = methods;


    const onUpdate = () => {
        if (isEdit) {
            let formData = new FormData();
            formData.append('name', name);
            formData.append('darkMode', darkMode);
            formData.append('isActive', isActive);
            axios.put(`/riders/update/${id}`, formData).then((res) => {
                console.log(res);
                enqueueSnackbar("Rider data updated sucessfully");
                navigate(PATH_DASHBOARD.allriders.riderList);
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar(err.message, { variant: "error" });
            })
        }
    }
    // useEffect(() => {
    //     getRiders();
    // }, []);

    // const getRiders = () => {
    //     axios.get(`/riders/get/all`, {
    //         // config
    //     }).then((res) => {
    //         // console.log(res.data);
    //         //  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    //         setRiderList(res.data.result);
    //         // setId(res.data.result.mobile);
    //     }).catch((err) => {
    //         console.log("error", err)
    //     })
    // }


    const onSubmit = () => {
        if (!isEdit) {
            let formData = new FormData();
            formData.append('mobile', mobile);
            formData.append('cityId', cityId);
            formData.append('type', type);
            axios.post('/riders/add', formData).then((res) => {
                console.log(res);
                enqueueSnackbar("Rider added successfully");
                navigate(PATH_DASHBOARD.allriders.riderList);
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar(err.message, { variant: "error" });
            })
        }

    }

    console.log(ridersList,"kjhuytre5rdey");


    const handleFileChange = (event) => {
        setIcon(event.target.files[0]);
      };
    

    return (
        <>
            <ThemeProvider theme={theme}>
                {!isEdit ? (<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new Rider' : 'Edit Rider'}
          links={[
            { name: 'Master Tables', href: PATH_DASHBOARD.root },
            {
              name: 'Riders',
              href: PATH_DASHBOARD.allriders.root,
            },
           
          ]}
        />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Card sx={{ p: 3 }}>

                                <Grid> <Typography>Upload Avatar</Typography>
                                    <IconButton color="primary" aria-label="upload picture" component="label">
                                        <input hidden accept="image/*"  type="file"  onChange={handleFileChange} required />
                                        <PhotoCamera fontSize='large' />
                                    </IconButton>
                                </Grid>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        columnGap: 8,
                                        rowGap: 3,
                                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                    }}
                                >


                                    <RHFTextField name="title" label="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
                                    <RHFTextField name="title" label="Type" value={type} onChange={(e) => setType(e.target.value)} required />
                                    <RHFTextField name="title" label="CityId" value={cityId} onChange={(e) => setCityId(e.target.value)} required />
                                    <RHFTextField name="title" label="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                                        <RHFTextField name="title" label="Dark Mode" value={darkMode} onChange={(e) => setDarkMode(e.target.value)} required />
                                        <RHFTextField name="title" label="Active" value={isActive} onChange={(e) => setIsActive(e.target.value)} required />
                                </Box>
                                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                                {/* <Button fullWidth color="inherit" variant="outlined" size="large" onClick={handleOpenPreview}>
                    Preview
                  </Button> */}
                                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting} >
                                  {!isEdit ? "Create Rider":"Save Changes"}
                                </LoadingButton>
                            </Stack>
                            </Card>
                        </Grid>


                            
                        </Grid>
                    
                </FormProvider>) : (

                    <FormProvider methods={methods} onSubmit={handleSubmit(onUpdate)}>
                         <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new Rider' : 'Edit Rider'}
          links={[
            { name: 'Master Tables', href: PATH_DASHBOARD.root },
            {
              name: 'Riders',
              href: PATH_DASHBOARD.allriders.root,
            },
           
          ]}
        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <Card sx={{ p: 3 }}>

                                    <Grid> <Typography>Upload Avatar</Typography>
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                            <input hidden accept="image/*" type="file"  onChange={handleFileChange} required/>
                                            <PhotoCamera fontSize='large' />
                                        </IconButton>
                                    </Grid>


                                    <Box
                                        sx={{
                                            display: 'grid',
                                            columnGap: 8,
                                            rowGap: 3,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                        }}
                                    >                           <RHFTextField name="title" label="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                        <RHFTextField name="title" label="Type" value={type} onChange={(e) => setType(e.target.value)} />
                                        <RHFTextField name="title" label="CityId" value={cityId} onChange={(e) => setCityId(e.target.value)} />
                                        <RHFTextField name="title" label="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                                        <RHFTextField name="title" label="Dark Mode" value={darkMode} onChange={(e) => setDarkMode(e.target.value)} required />
                                        <RHFTextField name="title" label="Active" value={isActive} onChange={(e) => setIsActive(e.target.value)} required />

                                    </Box>
                                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                                        {/* <Button fullWidth color="inherit" variant="outlined" size="large" onClick={handleOpenPreview}>
                    Preview
                  </Button> */}
                                        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting} >
                                            {!isEdit ? "Create Rider" : "Save Changes"}
                                        </LoadingButton>
                                    </Stack>
                                </Card>
                            </Grid>


                        </Grid>
                    </FormProvider>

                )}


            </ThemeProvider>
        </>
    );

}