import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Grid, Card, Chip, Stack, Button, TextField, Typography, Autocomplete,Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from '../../utils/axiosconfig';
// components
import { RHFSwitch, RHFEditor, FormProvider, RHFTextField, RHFUploadSingleFile ,RHFRadioGroup} from '../../components/hook-form';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// import axios from 'axios';

export default function NewTransaction() {
  const navigate = useNavigate();
  const [transaction,setTransaction] = useState("");
  const [type,setType] = useState("");
  const [isSuccessful,setIsSuccessful] = useState(false);
  const [amount,setAmount]= useState("");
  const [bookingId,setBookingId] = useState('');
  const [status,setStatus]=useState('');
  const [couponId,setCouponId] = useState('');

  const GENDER_OPTION = ['false','true'];

    const theme = createTheme({
        palette: {
          primary: {
            main: '#e65100',
          },
        },
      });

      const LabelStyle = styled(Typography)(({ theme }) => ({
        ...theme.typography.subtitle2,
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
      }));

      
      const methods = useForm({
        // resolver: yupResolver(NewUserSchema),
        // defaultValues,
      });

      const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid },
      } = methods;


      // const onSubmit = async () => {
      //   try {
      //     const payload = {};
      //     payload.reason = reason;
      //       payload.isActive = isActive;
      //       payload.type= type;
      //     const res = await fetch(`https://us-central1-pickupninjaapp.cloudfunctions.net/app/api/reasons/add`, {
      //       method: 'POST',
      //       headers: { "Content-Type": "application/json" },
      //       body: JSON.stringify(payload),
      //     });
      //     const result = await res.json();
      //     if (result) {
      //       reset();
      //       navigate(PATH_DASHBOARD.reason.reasons);
      //     }
      //   } catch (error) {
      //     console.error(error);
      //   }
      // };


const onSubmit = () => {
  const payload={};
  payload.amount=amount;
  payload.isSuccessful = isSuccessful;
  payload.type=type;
  payload.bookingId = bookingId;
  payload.couponId=couponId;
  payload.status = status;
   axios.post('/transactions/add', payload).then((res)=>{
    console.log(res);
    navigate(PATH_DASHBOARD.transaction.transactionList);
  }).catch((err)=>{
    console.log(err);
  })
}
    
    return (
        <>
        <ThemeProvider theme={theme}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <HeaderBreadcrumbs
          heading={ 'Create a new Transaction'}
          links={[
            { name: 'Master Tables', href: PATH_DASHBOARD.root },
            {
              name: 'Transactions',
              href: PATH_DASHBOARD.transaction.root,
            },
           
          ]}
        />
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                <Box
              sx={{
                display: 'grid',
                columnGap: 8,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
                    <RHFTextField name="title" label="Booking Id" value={bookingId} onChange={(e) => setBookingId(e.target.value)} required/>
                    <RHFTextField name="title" label="Coupon Id" value={couponId} onChange={(e) => setCouponId(e.target.value)} required/>
                    <RHFTextField name="title" label="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} required/>
                    <RHFTextField name="title" label="Status" value={status} onChange={(e) => setStatus(e.target.value)} required/>
                    <RHFTextField name="title" label="Type" value={type} onChange={(e) => setType(e.target.value)} required/>
                    <div>
                    <LabelStyle>Successful</LabelStyle>
                    <RHFRadioGroup
                    name="gender"
                    label="Successful"
                     options={GENDER_OPTION}
                    sx={{
                      '& .MuiFormControlLabel-root': { mr: 4 },
                    }}
                    value={isSuccessful}
                    onChange={(e) => setIsSuccessful(e.target.value)}
                    required
                  />
                   
                    </div>
    
                  </Box>
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  {/* <Button fullWidth color="inherit" variant="outlined" size="large" onClick={handleOpenPreview}>
                    Preview
                  </Button> */}
                  <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting} >
                    Create Transaction
                  </LoadingButton>
                </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
    
    
          </ThemeProvider>
        </>
      );

}