// import * as Yup from 'yup';
import {  useState, useEffect } from 'react';
// import { paramCase, capitalCase } from 'change-case';
// import { useSnackbar } from 'notistack';
import { useNavigate, useLocation, useParams,  } from 'react-router-dom';
// form
// import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Card, Stack,Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from '../../utils/axiosconfig';
// components
import { FormProvider, RHFTextField,  } from '../../components/hook-form';
// import AllRiders from '../riders/allRiders';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// import axios from 'axios';

export default function NewUsers() {
    const navigate = useNavigate();

    const { pathname } = useLocation();
    const [mobile, setMobile] = useState("");
    const [name, setName] = useState("");
    const [darkMode, setDarkMode] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const isEdit = pathname.includes('edit');
    const [userList, setUserList] = useState([]);

    const { id } = useParams();


   
    // const history = useHistory();
    // const GENDER_OPTION = ['false', 'true'];

    const theme = createTheme({
        palette: {
            primary: {
                main: '#e65100',
            },
        },
    });

    // const LabelStyle = styled(Typography)(({ theme }) => ({
    //     ...theme.typography.subtitle2,
    //     color: theme.palette.text.secondary,
    //     marginBottom: theme.spacing(1),
    // }));


    const methods = useForm({
        // resolver: yupResolver(NewUserSchema),
        // defaultValues,
    });

    const {
        reset,
    
        handleSubmit,
        formState: { isSubmitting },
    } = methods;


    const onUpdate = () => {

        const payload = {};
        payload.name = name;
        payload.darkMode = darkMode;
        payload.isActive = isActive;
        axios.put(`/users/update/${id}`, payload).then((res) => {
            console.log(res);
            navigate(PATH_DASHBOARD.allusers.userList);
        }).catch((err) => {
            console.log(err);
        })
    }


    const getUsers = () => {
        axios.get(`/users/get/all`, {
            // config
        }).then((res) => {
            console.log(res.data);
            //  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
            setUserList(res.data.result);
        }).catch((err) => {
            console.log("error", err)
        })
    }
    const currentUser = userList.find((user) =>user.id === id);

    console.log(".mngt567d", currentUser);



    const onSubmit = () => {
        if (!isEdit) {
            const payload = {};
            payload.mobile = mobile;
            axios.post('/users/add', payload).then((res) => {
                console.log(res);
                navigate(PATH_DASHBOARD.allusers.userList);
            }).catch((err) => {
                console.log(err);
            })
        }

    }
    useEffect(() => {
        getUsers();
        if (isEdit && currentUser === " ") {
            reset();
        }
    }, [isEdit, currentUser,reset]);

    return (
        <>
            <ThemeProvider theme={theme}>
                {!isEdit ? (<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                    <HeaderBreadcrumbs
                        heading={!isEdit ? 'Create a new User' : 'Edit User'}
                        links={[
                            { name: 'Master Tables', href: PATH_DASHBOARD.root },
                            {
                                name: 'Users',
                                href: PATH_DASHBOARD.allusers.root,
                            },

                        ]}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Card sx={{ p: 3 }}>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        columnGap: 8,
                                        rowGap: 3,
                                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                    }}
                                >
                                    <RHFTextField name="title" label="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
                                    <RHFTextField name="title" label="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                                    <RHFTextField name="title" label="Dark Mode" value={darkMode} onChange={(e) => setDarkMode(e.target.value)} required />
                                    <RHFTextField name="title" label="Active" value={isActive} onChange={(e) => setIsActive(e.target.value)} required />
                                </Box>

                                <Stack sx={{ mt: 3 }} alignItems="flex-end">
                                    {/* <Button fullWidth color="inherit" variant="outlined" size="large" onClick={handleOpenPreview}>
                    Preview
                  </Button> */}
                                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting} >
                                        {!isEdit ? "Create User" : "Save Changes"}
                                    </LoadingButton>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </FormProvider>) : (

                    <FormProvider methods={methods} onSubmit={handleSubmit(onUpdate)}>
                        <HeaderBreadcrumbs
                            heading={!isEdit ? 'Create a new User' : 'Edit User'}
                            links={[
                                { name: 'Master Tables', href: PATH_DASHBOARD.root },
                                {
                                    name: 'Users',
                                    href: PATH_DASHBOARD.allusers.root,
                                },

                            ]}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <Card sx={{ p: 3 }}>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            columnGap: 8,
                                            rowGap: 3,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                        }}
                                    >
                                        <RHFTextField name="title" label="Mobile" value={currentUser?.mobile} onChange={(e) => setMobile(e.target.value)} />
                                        <RHFTextField name="title" label="Name" value={currentUser?.name} onChange={(e) => setName(e.target.value)} />
                                        <RHFTextField name="title" label="Dark Mode" value={currentUser?.darkMode} onChange={(e) => setDarkMode(e.target.value)} />
                                        <RHFTextField name="title" label="Active" value={currentUser?.isActive} onChange={(e) => setIsActive(e.target.value)} />

                                    </Box>
                                    <Stack sx={{ mt: 3 }} alignItems="flex-end">
                                        {/* <Button fullWidth color="inherit" variant="outlined" size="large" onClick={handleOpenPreview}>
                    Preview
                  </Button> */}
                                        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting} >
                                            {!isEdit ? "Create User" : "Save Changes"}
                                        </LoadingButton>
                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </FormProvider>

                )}


            </ThemeProvider>
        </>
    );

}