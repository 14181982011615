import PropTypes from "prop-types";
import Login from "../pages/auth/Login";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const isAuthenticated = localStorage.getItem("pickup-ninja-token");

  if (!isAuthenticated) {
    return <Login />;
  }
  return <>{children}</>;
}
