// import * as Yup from 'yup';
import { useContext, useState } from 'react';
import larrow from "../../images/arrow-left.svg";
// import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// form
// import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
// import { LoadingButton } from '@mui/lab';
import {ThemeProvider } from '@mui/material/styles';
// import Switch from '@mui/material/Switch';
import { Grid, Card,  Button,  Container, Typography, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from '../../utils/axiosconfig';
// import Page from '../../components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// components
import {  FormProvider, RHFTextField } from '../../components/hook-form';
// import Label from 'src/components/Label';
import Filter from '../common/filter';
// import { position } from 'stylis';
import useSettings from '../../hooks/useSettings';
import { MainContext } from 'src/contexts/context';
import ninja_head from '../../images/ninja_head.svg';
import { SeoIllustration } from "../../assets";

export default function NewGroup() {
  const { themeStretch } = useSettings();
  
  
  const [groupName, setGroupName] = useState("");
  const [field, setField] = useState("");
  const [values, setValues] = useState("");
  const [operator, setOperator] = useState("")
  const {managerId} = useContext(MainContext);
  const [isFormula, setIsFormula] = useState(false);
  const { pathname } = useLocation();
  const formulaArray = {
    field: field,
    operator: operator,
    value: values
  }

  // const { id } = useParams();
  const isEdit = pathname.includes('edit');

  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  const {
  
    handleSubmit,
    
  } = methods;


  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };


  const onSubmit = () => {
    if (!isEdit) {
      
      const payload = [];
      payload.push(formulaArray)
      const data = {
        groupName: groupName,
        isAuto: true,
        managerId:managerId,
        formula: payload
      }
      console.log(data);
      if(formulaArray.field && formulaArray.operator && formulaArray.value){
      axios.post('/groups/add', data).then((res) => {
        console.log(res);
        navigate(PATH_DASHBOARD.group.groups);
      }).catch((err) => {
        console.log(err);
      })
    }
  }}
    // {

    //   const payload = {};
    //   payload.groupName = groupName;
    //   payload.isAuto = isAuto;
    //   payload.formula = formula;
    //   axios.put(`/groups/update/${id}`, payload).then((res) => {
    //     console.log(res);
    //     navigate(PATH_DASHBOARD.group.groups);
    //   }).catch((err) => {
    //     console.log(err);
    //   })

    // }
  
  // const LabelStyle = styled(Typography)(({ theme }) => ({
  //   ...theme.typography.subtitle2,
  //   color: theme.palette.text.secondary,
  //   marginBottom: theme.spacing(1),
  // }));

  console.log(operator, values, field, formulaArray)
  return (
    <>

      <ThemeProvider>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Box sx={{ width: '100%' }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0' }} style={{display: "flex",borderBottom: "1px solid #E04E2C",}} >
              <img
                src={larrow}
                onClick={handleOnClick}
                height={20}
                style={{
                  marginRight: "10px",
                  marginTop: "7px",
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
              /> 
                {!isEdit ? (  
                <Typography 
                sx={{ 
                  color: "#030303", 
                  fontSize: "24px", 
                  fontWeight: "300", 
                  }}
                  >
                    CREATE A NEW GROUP
                </Typography>
                ) : (
                <Typography 
                sx={{ 
                  color: "#030303", 
                  fontSize: "24px", 
                  fontWeight: "300", 
                  borderBottom: "1px solid #E04E2C" 
                  }}
                  >
                  EDIT GROUP
                  </Typography>
                  )}
              </Box>
              <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0', paddingTop: '0' }}>
                <HeaderBreadcrumbs
                  heading={''}
                  links={[
                    { name: 'Groups', href: PATH_DASHBOARD.allusers.userList },
                    {
                      name: 'Add new',
                      href: PATH_DASHBOARD.group.root,
                    },

                  ]}
                />
              </Box>

              <Grid container spacing={3} className="px-0" sx={{marginBottom:"30px" }}>
                <Grid item xs={12} md={8} lg={8}>
                  <Card sx={{ p: 3, borderRadius: "0"}}>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid container spacing={4} sx={{justifyContent:"space-between",padding:"24px",alignItems:"center", display:"flex"}}>
                          <Grid item xs sx={{alignSelf:"flex-start"}}>
                            <Box>
                            <RHFTextField 
                              name="title" 
                              label="Group Name" 
                              value={groupName} 
                              onChange={(e) => setGroupName(e.target.value)} 
                              required 
                              />

                            <Typography sx={{marginTop:"5px"}}>Formula </Typography>
                            <Filter setOperator={setOperator} setValues={setValues} values={values} setField={setField} field={field} setIsFormula={setIsFormula} />
                            {
                              isFormula ?
                                <Typography style={{ 
                                  position: 'absolute', 
                                  top: '-46px', 
                                  left: '400px', 
                                  fontSize: '14px', 
                                  fontWeight: '700', 
                                  color: '#666666' 
                                }}>
                                  {`${field} ${operator} ${values}`}
                                </Typography> : <></>
                            }
                          <Button sx={{marginTop:"43px", width:"500px"}} type="submit" variant="contained" fullWidth size="large" onClick={() => onSubmit()}>
                            {!isEdit ? "Create Group" : "Save Changes"}
                          </Button>
                          </Box>
                          </Grid>
                          <Grid item xs>
                            {isFormula ?  <Grid item xs sx={{ alignSelf:"center"}}>
                            <SeoIllustration sx={{ height: 350}} />
                            <Typography>
                            At Pickup Ninja, we make it easy for you to send notifications to a specific group of people. Whether you want to share updates, reminders, or important information, creating a notification group is simple and efficient.
                            </Typography>
                              
                            </Grid>: <Grid item xs sx={{ alignSelf:"flex-start"}}>
                            <SeoIllustration sx={{ height: 350}} />
                            <Typography>
                            At Pickup Ninja, we make it easy for you to send notifications to a specific group of people. Whether you want to share updates, reminders, or important information, creating a notification group is simple and efficient.
                            </Typography>
                              
                            </Grid>}
                          </Grid>
                           
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={6} md={6} lg={6}>
                      </Grid> */}
                    </Grid>
                  </Card>
                </Grid>


              </Grid>


            </FormProvider>
          </Box>
        </Container >
      </ThemeProvider >
    </>
  );

}