import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { List, Box, ListSubheader } from "@mui/material";
//
import { NavListRoot } from "./NavList";
import { useState, useEffect } from "react";
import axios from "../../../utils/axiosconfig";
import { useContext } from "react";
import { MainContext } from "src/contexts/context";
import localStorage from "redux-persist/es/storage";
// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  // paddingTop: theme.spacing(3),
  // paddingLeft: theme.spacing(2),
  // paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}) {
  const { setGlobalState } = useContext(MainContext);
  // const { type } = useContext(MainContext);
  const [manager, setManager] = useState({});
  const [admin, setAdmin] = useState("");
  const mid = window.localStorage.getItem("mid");
  const obj = window.localStorage.getItem("obj");
  console.log("///////////", obj);
  console.log(mid, "/////////");
  useEffect(() => {
    if (mid) {
      getManager();
    }
  }, [mid]);

  const getManager = () => {
    axios
      .get(`managers/get/uId/${mid}`)
      .then((res) => {
        console.log(res.data);
        console.log(res.data.result.cityId);
        setManager(res.data.result);
        localStorage.setItem("manager_city", res.data.result.cityId);
        localStorage.setItem("manager_id", res.data.result.id);
        setGlobalState((prev) => {
          return {
            ...prev,
            managerId: res.data.result.id,
            cityId: res.data.result.cityId,
          };
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      {obj !== null ? (
        <Box {...other}>
          {navConfig.slice(1).map((group) => (
            <List key={group.subheader} sx={{ p: 0 }}>
              {group.items.map((list) => (
                <NavListRoot
                  key={list.title}
                  list={list}
                  isCollapse={isCollapse}
                />
              ))}
            </List>
          ))}
        </Box>
      ) : (
        <Box {...other}>
          {navConfig.slice(0, 1).map((group) => (
            <List key={group.subheader} sx={{ p: 0 }}>
              {group.items.map((list) => (
                <NavListRoot
                  key={list.title}
                  list={list}
                  isCollapse={isCollapse}
                />
              ))}
            </List>
          ))}
        </Box>
      )}
    </>
  );
}
