import * as Yup from 'yup';
import { useCallback, useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import larrow from '../../images/arrow-left.svg';
import Switch from '@mui/material/Switch'
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Grid, Card, Chip, Container, Stack, Button, TextField, Typography, Autocomplete, Box, InputAdornment } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from '../../utils/axiosconfig';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// components
import { RHFSwitch, RHFEditor, FormProvider, RHFTextField, RHFUploadSingleFile, RHFRadioGroup, RHFSelect, RHFTextArea } from '../../components/hook-form';
import coupon from '../../images/coupon.png';
import useSettings from '../../hooks/useSettings';
import { MainContext } from 'src/contexts/context';
import CouponList from './couponList';
import { current } from '@reduxjs/toolkit';
import { Textarea } from '@mui/joy';


export default function NewCoupon() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [couponList, setCouponList] = useState([]);
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const [searchValue, setSearchValue] = useState();
  const [filterData, setFilterData] = useState();
  const [filterValue, setFilterValue] = useState();
  const [type, setType] = useState("");
  const [type1, setType1] = useState('flat');
  const [limit, setLimit] = useState(0);
  const cityId = localStorage.getItem("manager_city");
  const Coupontype = "flat";
  const [page, setPage] = useState(0);
  const GENDER_OPTION = ['false', 'true'];
  const theme = createTheme({
    palette: {
      primary: {
        main: '#e65100',
      },
    },
  });
  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  // const handelUserLimit = (e) => {   
  //   setUserLimit(e.target.value);
  //   if (parseInt(e.target.value) > parseInt(totalLimit)) {
  //     alert('User limit cannot be greater than total limit.');
  //   } else {
  //     setUserLimit(userLimit);
  //   }
  // }

  const handelTotalLimit = (e) => {
    setTotalLimit(e.target.value)
    if (parseInt(userLimit) < parseInt(e.target.value)) {
      alert('User limit cannot be greater than total limit.');
    } else {
      setTotalLimit(totalLimit);
    }
  }

  const handleStartTimeChange = (startTime) => {
    // Convert the startTime to a Date object
    const startDate = new Date(startTime);

    // Get the number format of the startTime using getTime()
    const startTimeInNumberFormat = startDate.getTime();

    // Assuming endTime is already defined
    const endTime = new Date(); // Replace this with your endTime logic

    // Convert endTime to number format using getTime()
    const endTimeInNumberFormat = endTime.getTime();

    // Compare the startTime and endTime in number format
    if (startTimeInNumberFormat > endTimeInNumberFormat) {
      alert('Start time must be before end time');
    } else {
      // Perform your logic if start time is before or equal to end time
      setStartTime(startTime);
    }
  };


  const handleEndTimeChange = (endTime) => {

    if (endTime < startTime) {

      alert('End time must be after start time');
    } else {

      setEndTime(endTime);
    }
  };

  useEffect(() => {
    if (couponList) {
      getCoupon();
    }
  }, [type, searchValue, filterValue])

  const types = [
    {
      id: 1, label: "flat"
    },
    {
      id: 2, label: "percentage"
    }
  ]

  const [defaultValues, setDefaultValues] = useState(null);
  const [warning, setWarning] = useState('');
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [discount, setDiscount] = useState();
  const [promoted, setPromoted] = useState(true);
  const [maximum, setMaximum] = useState(null);
  const [startTime, setStartTime] = useState(new Date());
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [endTime, setEndTime] = useState(tomorrow);
  const [userLimit, setUserLimit] = useState("");
  const [totalLimit, setTotalLimit] = useState("");
  const [description, setDescription] = useState("");
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const getCoupon = () => {
    const payload = {}
    payload.cityId = cityId;
    payload.type = type1;
    payload.filter = filterValue;
    payload.search = searchValue;
    payload.page = page;
    payload.limit = limit;

    axios.post(`/coupons/get/all`, payload).then((res) => {
      setCouponList(res.data.result);
      console.log("uuuuuuuuu", res.data.result);
      const currentCoupon = res.data.result.find((item) => item.id === id);
      setCode(currentCoupon?.code);
      setDiscount(currentCoupon?.discount);
      setMaximum(currentCoupon?.maximum);
      setPromoted(currentCoupon?.promoted);
      // setType1(currentCoupon?.type);
      // setStartTime(currentCoupon?.startTime);
      // setEndTime(currentCoupon?.endTime);
      // setUserLimit(currentCoupon?.userLimit);
      // setTotalLimit(currentCoupon?.totalLimit);
      setDescription(currentCoupon?.description);
      setTitle(currentCoupon?.title);
      console.log("resultttt", res.data.result);
    }).catch((err) => {
      console.log("error", err);
    })
  }

  const handleOnClick = () => {
    navigate(-1);
  };

  const onSubmit = () => {
    if (!isEdit) {
      const payload = {}; 
      payload.cityId = cityId;
      payload.code = code;
      payload.type = type1;
      payload.maximum = maximum;
      payload.startTime = startTime;
      payload.endTime = endTime;
      payload.userLimit = userLimit;
      payload.totalLimit = totalLimit;
      payload.discount = discount;
      payload.title = title;
      payload.description = description;
      payload.promoted = promoted;
      
      console.log('testingfffff', payload)
      axios.post('/coupons/add', payload).then((res) => {
        console.log("testing",res);
        navigate(PATH_DASHBOARD.coupon.coupons);
      }).catch((err) => {
        console.log(err);
      })
    }
    else {
      const payload = {};
      payload.cityId = cityId;
      payload.code = code;
      payload.type = type1;
      payload.maximum = maximum;
      payload.startTime = startTime;
      payload.endTime = endTime;
      payload.userLimit = userLimit;
      payload.totalLimit = totalLimit;
      payload.discount = discount;
      payload.title = title;
      payload.promoted = promoted;
      payload.description = description;

      axios.put(`/coupons/update/${id}`, payload).then((res) => {
        console.log(res);
        navigate(PATH_DASHBOARD.coupon.coupons);
      }).catch((err) => {
        console.log(err);
      })

    }
  }

  return (
    <>
      <ThemeProvider>
        <Container maxWidth={themeStretch ? false : 'xl'} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box sx={{ width: '100%' }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0' }}
                style={{
                  display: "flex",
                  borderBottom: "1px solid #E04E2C"

                }}>
                <Box>
                  <img
                    src={larrow}
                    onClick={handleOnClick}
                    height={20}
                    style={{
                      marginRight: "10px",
                      marginTop: "7px",
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Box>{!isEdit ? (<Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300" }}>CREATE NEW COUPON</Typography>) : 
                (<Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300" }}>EDIT COUPON</Typography>)}</Box>
              </Box>
              <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0', paddingTop: '0' }}>
                <HeaderBreadcrumbs

                  heading={''}
                  links={[
                    { name: 'Coupons', href: PATH_DASHBOARD.coupon.coupons },
                    {
                      name: 'Add new',
                      href: PATH_DASHBOARD.coupon.root,
                    },

                  ]}
                />
              </Box>

              <Grid container flexWrap='wrap'   >
                <Box container item marginBottom='30px' flexGrow="1">
                  <Box sx={{ background: 'white', padding: '30px', ml: 3 }}>
                    <Box
                      sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                      }}

                    >
                      {/* <Box sx={{display:"flex",gap:"10px"}}> */}
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">Type</Typography>
                        <RHFSelect sx={{ width: "100%" }} style={{ color: "grey", fontSize: "13px" }}
                          name="title" value={type1}
                          defaultValue="default-value"
                          onChange={(e) => setType1(e.target.value)}
                          required >
                          <option value="percentage">percentage</option>
                          <option value="flat" selected>flat</option>

                        </RHFSelect>
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5" >Discount</Typography>
                        <RHFTextField sx={{ width: "100%" }} name="title"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{type1 === "flat" ? "₹" : "%"}</InputAdornment>
                          }}
                          value={discount}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (type1 === 'percentage') {
                              // Allow only one or two-digit numbers for percentage type
                              if (/^\d{0,2}$/.test(inputValue)) {
                                setDiscount(inputValue);
                              } else {
                                // Display an alert if more than two digits are entered
                                alert('Please enter a valid percentage');
                              }
                            } else {
                              // For other types, set the discount directly
                              setDiscount(inputValue);
                            }
                          }}
                          type="number"
                          required />
                        <Box>{warning && <Typography style={{ color: 'red' }}>{warning}</Typography>}</Box>
                      </Box>


                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">Title</Typography>
                        <RHFTextField sx={{ width: "100%" }} name="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">Start Time</Typography>
                        <DateTimePicker name="title" label="Start Time" value={startTime} onChange={handleStartTimeChange} className="custom-calendar" calendarClassName="rasta-stripes" />
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">End Time</Typography>
                        <DateTimePicker label="Start Time" value={endTime} onChange={handleEndTimeChange} className="custom-calendar" calendarClassName="rasta-stripes" />
                      </Box>
                      {/* <TextField label="Start Date" inputProps={{ type: 'date' }} value={endTime} onChange={setEndTime}/> */}

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">Code</Typography>
                        <TextField sx={{ width: "100%" }} name="title" value={code} onChange={(e) => setCode(e.target.value)} required />
                      </Box>
                      {/* </Box>
                      <Box sx={{display:"flex",gap:"10px",height:"53px",width:"620px"}}> */}               
                          {
                            type1 === "percentage" && (
                              <Box>
                            <Typography className="para_label" variant="h5">Maximum</Typography>
                            <RHFTextField
                              sx={{ width: "100%" }}
                              name="title"
                              value={maximum}
                              onChange={(e) => setMaximum(e.target.value)}
                            />
                          </Box>
                            ) 
                          }
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">User Limit</Typography>
                        <RHFTextField sx={{ width: "100%" }} name="title" type="number" value={userLimit} onChange={(e) => setUserLimit(e.target.value)} required />
                      </Box>
                      <Box>
                        <Box sx={{}}>
                        <Typography className="para_label" variant="h5">Total Limit</Typography>
                        <RHFTextField sx={{ width: "100%" }} name="title" type="number" value={totalLimit} required onChange={(e) => setTotalLimit(e.target.value)}
                        />
                      </Box>
                      </Box>
                     
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">Description</Typography>
                        {/* <RHFTextArea className="para_text" sx={{ width: "100%" }} name="title" type="number" value={description} onChange={(e) => setDescription(e.target.value)} required /> */}
                        <textarea 
                        placeholder=" Information about the Coupon" 
                        style={{
                          fontSize: '14px', 
                          height:"100px",
                          width: "100%",
                          color: "#020202", 
                          fontWeight: 500, 
                          borderRadius: "8px", 
                          resize: 'none', 
                          fontFamily: 'Inter,sans-serif',
                          border: '1px solid #E2E1E5', 
                          padding: '4px 10px' 
                          }} name="title" label="Message" value={description} onChange={(e) => setDescription(e.target.value)} required />
                      </Box>
                      <Box>
                        <Typography className="para_label" variant="h5">Promoted</Typography>
                        <Switch value={promoted}  onChange={(e) => setPromoted(e.target.value)} color="warning" />
                      </Box>
                    </Box>

                    {/* </Box> */}
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                      {/* <Button fullWidth color="inherit" variant="outlined" size="large" onClick={handleOpenPreview}>
                    Preview
                  </Button> */}
                      <LoadingButton sx={{ padding: "15px 0", borderRadius: '5px' }} type="submit" fullWidth variant="contained" size="large" loading={isSubmitting}>
                        {!isEdit ? "ADD" : "Save Changes"}
                      </LoadingButton>
                    </Stack>
                  </Box>
                </Box>
                <Box sx={{ ml: 3,width:"30%" }} flexGrow="1" item  >
                  <Card sx={{ p: 3 }}>
                    <Typography className="para_label" variant="h5">Preview</Typography>
                    <Box sx={{ display: "flex", boxShadow: "0 0 7px 0px #e3e3e3", borderRadius: "30px", padding: '15px 16px', alignItems: 'center' }}>
                      <Box style={{ marginRight: '15px', height: '70px', width: '70px', borderRadius: '50%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={coupon} alt="kjhg" style={{ height: '3.64vw' }} />
                      </Box>
                      <Box>
                        <Typography sx={{ color: "#020202", fontSize: "16px", fontWeight: 700, marginBottom: "10px" }}>{title}</Typography>
                        <Typography sx={{ color: "#020202", fontSize: "16px", fontWeight: 700, marginBottom: "10px" }}>Discount: {type1 === "flat" && "₹ "}{discount}{type1 === "percentage" && " %"}</Typography>
                        <Typography sx={{ color: "#020202", fontSize: "16px", fontWeight: 700, marginBottom: "10px" }}>Coupon Code: {code}</Typography>
                        <Typography sx={{ color: "#020202", fontSize: "16px", fontWeight: 700, marginBottom: "10px", wordBreak:"break-all"}}>{description}</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );

}