const breakpoints = {
  values: {
    xs: 0,   // Extra small devices (phones)
    sm: 600, // Small devices (tablets)
    md: 960, // Medium devices (desktops)
    lg: 1280,// Large devices (desktops)
    xl: 1960,// Extra large devices (large desktops)
  }
};

export default breakpoints;