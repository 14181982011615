import * as Yup from 'yup';
import { useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import larrow from '../../images/arrow-left.svg';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Grid, Card, Chip, Stack, Button, TextField, Typography, Container, Autocomplete, Box } from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { fData } from '../../utils/formatNumber';
import axios from '../../utils/axiosconfig';
// components
import { RHFSwitch, RHFEditor, FormProvider, RHFTextField, RHFUploadAvatar, RHFRadioGroup } from '../../components/hook-form';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Group from '../../images/Group.svg';
import Plus from '../../images/Plus.svg';
// hooks
import useSettings from '../../hooks/useSettings';
import { MainContext } from 'src/contexts/context';

export default function NewReason() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [icon, setIcon] = useState(null);
  const [iconimage, setIconImage] = useState();
  const [url, setUrl] = useState();
  const [created, setCreated] = useState("");
  const [modified, setModified] = useState("");
  const { pathname } = useLocation();
  const {managerId} = useContext(MainContext);
  const { id } = useParams();
  const isEdit = pathname.includes('edit');
  const { enqueueSnackbar } = useSnackbar();
  const GENDER_OPTION = ['false', 'true'];

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }));


  const theme = createTheme({
    palette: {
      primary: {
        main: '#e65100',
      },
    },
  });
  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;
  const values = watch();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleRemoveAll = () => {
    setValue('images', []);
  };
  
  const handleOnClick = () => {
    navigate(-1);
  };
  const handleRemove = (file) => {
    const filteredItems = values.images?.filter((_file) => _file !== file);
    setValue('images', filteredItems);
  };


  // const onSubmit = async () => {
  //   try {
  //     const payload = {};
  //     payload.title = title;
  //     payload.message = message;
  //     payload.isDefault = isDefault;
  //     payload.isActive = isActive;
  //     payload.icon = icon;
  //     payload.url = url;
  //     payload.created = created;
  //     payload.modified = modified;
  //     const res = await fetch(`https://us-central1-pickupninjaapp.cloudfunctions.net/app/api/notifications/add`, {
  //       method: 'POST',
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(payload),
  //     });
  //     const result = await res.json();
  //     if (result) {
  //       reset();
  //       navigate(PATH_DASHBOARD.notification.notifications);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleFileChange = (event) => {
    setIcon(event.target.files[0]);
    setIconImage(URL.createObjectURL(event.target.files[0]));
    setUrl(URL.createObjectURL(event.target.files[0]))
  };
  const onSubmit = () => {
    if (!isEdit) {
      let formData = new FormData();
      formData.append('icon', icon);
      formData.append('title', title);
      formData.append('message', message);
      formData.append('isDefault', isDefault);
      formData.append("managerId",managerId);
      axios.post('/notifications/add', formData).then((res) => {
        console.log(res);
        navigate(PATH_DASHBOARD.notification.notifications);
        enqueueSnackbar("Notification added successfully!");
      }).catch((err) => {
        console.log(err);
      })

    }
    else {

      let formData = new FormData();
      formData.append('icon', icon);
      formData.append('title', title);
      formData.append('message', message);
      formData.append('isDefault', isDefault);
      formData.append("managerId",managerId);
      axios.put(`/notifications/update/${id}`, formData).then((res) => {
        console.log(res);
        navigate(PATH_DASHBOARD.notification.notifications);
      }).catch((err) => {
        console.log(err);
      })

    }
  }



  return (
    <>
      <ThemeProvider>
        <Container maxWidth={themeStretch ? false : 'xl'} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box sx={{ width: "100%" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0', display: "flex", borderBottom: "1px solid #E04E2C"  }}>
              <img
                    src={larrow}
                    onClick={handleOnClick}
                    height={20}
                    style={{
                      marginRight: "10px",
                      marginTop: "7px",
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                  />
                {!isEdit ? (<Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300"}}>ADD NEW NOTIFICATION</Typography>) : (<Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300", borderBottom: "1px solid #E04E2C" }}>EDIT NOTIFICATION</Typography>)}
              </Box>
              <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0', paddingTop: '0' }}>
                <HeaderBreadcrumbs
                  heading={''}
                  links={[
                    { name: 'Notifications', href: PATH_DASHBOARD.notification.notifications },
                    {
                      name: 'Add new',
                      href: PATH_DASHBOARD.notification.root,
                    },

                  ]}
                />
              </Box>
              <Grid container spacing={3} className="px-0" sx={{ paddingLeft: "24px", paddingRight: "24px" }}>
                <Grid item xs={12} md={6} lg={6} sx={{ gap: "100px" }}>
                  <Card sx={{ p: 3, borderRadius: "0" }}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <IconButton variant="contained" component="label" className="iconBtn">
                          <input hidden accept="image/*" type="file" onChange={handleFileChange} required />
                          {/* <PhotoCamera fontSize='large' /> */}
                          <img src={url ? url : Plus} alt="jhhjh"/>
                        </IconButton>
                        <Typography sx={{ color: "#909090", fontSize: "14px", fontWeight: "500", marginBottom: "15px", textAlign: "center" }}>Icon</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography  className="para_label" style={{marginTop: '15px'}}>Enter the Meta Title here</Typography>
                        <RHFTextField name="title" placeholder="Enter the Meta Title here" value={title} onChange={(e) => setTitle(e.target.value)} required /></Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography  className="para_label">Message</Typography>
                        <textarea placeholder="Type Notification Body here" style={{ height: "155px",fontSize: '16px', width: "100%", fontFamily: 'Inter,sans-serif', borderRadius: "8px", resize: 'none', border: '1px solid #E2E1E5', padding: '10px' }} name="title" label="Message" value={message} onChange={(e) => setMessage(e.target.value)} required />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <LoadingButton sx={{ padding: "15px 0", borderRadius:"5px" }} type="submit" fullWidth variant="contained" size="large" loading={isSubmitting}>
                          {!isEdit ? "ADD" : "Save Changes"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                  <Card sx={{ p: 3, padding: '30px', borderRadius: "0" }}>
                    <Typography  className="para_label">Preview</Typography>
                    <Box sx={{ display: "flex", boxShadow: "0 0 7px 0px #e3e3e3", borderRadius: "30px", padding: '15px 16px', alignItems: 'flex-start' }}>
                      <Box style={{ marginRight: '15px', width: '80px', borderRadius: '50%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={iconimage ? iconimage : Group} alt="kjhg" style={{ height: '60px' }} />
                      </Box>
                      <Box sx={{flex: '1'}}>
                        <Typography sx={{ color: "#020202", fontSize: "16px", fontWeight: 700, marginBottom: "10px" }}>{title == "" ? "This is the title of notification" : title}</Typography>
                        <Typography sx={{ color: "#020202", fontSize: "16px", fontWeight: 300 }}>{message == "" ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tempus, nunc non efficitur euismod, risus turpis mollis tortor, ac condimentum nisl tellus at eros. Sed ....." : message}</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );

}