import * as Yup from 'yup';
import { useCallback, useEffect, useState,useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton, StaticDateTimePicker } from '@mui/lab';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Grid, Card, Chip, Container, Stack, Button, TextField, Typography, Autocomplete, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from '../../utils/axiosconfig';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// components
import { RHFSwitch, RHFEditor, FormProvider, RHFTextField, RHFUploadSingleFile, RHFRadioGroup, RHFSelect } from '../../components/hook-form';
import coupon from '../../images/coupon.png';
import useSettings from '../../hooks/useSettings';
import { MainContext } from 'src/contexts/context';
import {countries} from '../../utils/constants/_countries';
// import CouponList from './couponList';


export default function NewCity() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [cityList,setCityList]= useState([]);
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const [city, setCity] = useState("");
  const [country,setCountry] = useState('');
  const [state,setState] = useState('');
const [currency,setCurrency] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude,setLongitude] = useState('');
  const {cityId} = useContext(MainContext);
  const [page, setPage] = useState(0);
  const GENDER_OPTION = ['false', 'true'];
  const theme = createTheme({
    palette: {
      primary: {
        main: '#e65100',
      },
    },
  });
  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });
 

useEffect(()=>{
  if(cityList){
    getCity();}
  },[cityList])

const getCity = () => {
   
  axios.get("cities/get").then((res)=>{
    console.log(res.data);
   
    setCityList(res.data.result);
    const currentCity = cityList.find((item)=>item.id === id);
    if(currentCity){
    setCountry(currentCity.country);
    setState(currentCity.state);
    setCity(currentCity.city);
    setCurrency(currentCity.currency);
    setLatitude(currentCity.latitude);
    setLongitude(currentCity.longitude);
    }
}).catch((err)=>{
  console.log("error",err)
})
}

  const types = [
    {
      id: 1, label: "flat"
    },
    {
      id: 2, label: "percent"
    }
  ]

  const {
   
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  


  const onSubmit = () => {
    if (!isEdit) {
      const payload = {};
      payload.city = city;
      payload.country = country;
      payload.state = state;
      payload.latitude = latitude;
      payload.longitude = longitude;
      payload.currency = currency;
      axios.post('/cities/add', payload).then((res) => {
        console.log(res);
        navigate(PATH_DASHBOARD.cities.cities);
      }).catch((err) => {
        console.log(err);
      })
    }
    else {

      const payload = {};
      payload.city = city;
      payload.country = country;
      payload.state = state;
      payload.latitude = latitude;
      payload.longitude = longitude;
      payload.currency = currency;
    
      axios.put(`/cities/update/${id}`, payload).then((res) => {
        console.log(res);
        navigate(PATH_DASHBOARD.cities.cities);
      }).catch((err) => {
        console.log(err);
      })

    }
  }

  return (
    <>
      <ThemeProvider>
        <Container maxWidth={themeStretch ? false : 'xl'} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box sx={{ width: '100%' }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0' }}>
                {!isEdit ? (<Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300", borderBottom: "1px solid #E04E2C" }}>CREATE NEW CITY</Typography>) : (<Typography sx={{ color: "#030303", fontSize: "24px", fontWeight: "300", borderBottom: "1px solid #E04E2C" }}>EDIT CITY</Typography>)}
              </Box>
              <Box className="px-0" sx={{ padding: '24px', paddingBottom: '0', paddingTop: '0' }}>
                <HeaderBreadcrumbs

                  heading={''}
                  links={[
                    { name: 'Cities', href: PATH_DASHBOARD.cities.cities },
                    {
                      name: 'Add new',
                      href: PATH_DASHBOARD.cities.root,
                    },

                  ]}
                />
              </Box>

              <Grid container spacing={3} className="px-0" sx={{ paddingLeft: "24px", paddingRight: "24px" }}>
                <Grid item xs={12} md={6} lg={6} >
                  <Box sx={{ background: 'white', padding: '15px' }}>
                    <Box
                      sx={{
                        display: 'grid',
                        columnGap: 4,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                      }}
                    >
                      {/* <Box sx={{display:"flex",gap:"10px"}}> */}
                      <Box sx={{}}>
                        <Typography className="para_label">Country</Typography>
                        <RHFSelect sx={{ width: "100%" }} name="title" value={country} onChange={(e) => setCountry(e.target.value)} required >
                          <option value="" />
                          {countries.map((option) => (
                            <option key={option.id} value={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </RHFSelect>
                      </Box>
                     
                      <Box sx={{}}>
                        <Typography className="para_label">State</Typography>
                        <RHFTextField sx={{ width: "100%" }}  value={state} onChange={(e) => setState(e.target.value)} required />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label">City</Typography>
                        <RHFTextField name="title"  value={city} onChange={(e)=>setCity(e.target.value)} className="custom-calendar" calendarClassName="rasta-stripes" />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label">Currency</Typography>
                        <RHFTextField  value={currency} onChange={(e)=>setCurrency(e.target.value)} className="custom-calendar" calendarClassName="rasta-stripes" />
                      </Box>
                      {/* <TextField label="Start Date" inputProps={{ type: 'date' }} value={endTime} onChange={setEndTime}/> */}

                      <Box sx={{}}>
                        <Typography className="para_label">Latitude</Typography>
                        <TextField  sx={{width: "100%" }} name="title" value={latitude} onChange={(e) => setLatitude(e.target.value)} required />
                      </Box>
                      {/* </Box>
                      <Box sx={{display:"flex",gap:"10px",height:"53px",width:"620px"}}> */}
                      <Box sx={{}}>
                        <Typography className="para_label">Longitude</Typography>
                        <RHFTextField sx={{ width: "100%" }} name="title" value={longitude} onChange={(e) => setLongitude(e.target.value)} required />
                      </Box>
                    
                    </Box>

                    {/* </Box> */}
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                      {/* <Button fullWidth color="inherit" variant="outlined" size="large" onClick={handleOpenPreview}>
                    Preview
                  </Button> */}
                      <LoadingButton sx={{ padding: "15px 0", borderRadius: '5px' }} type="submit" fullWidth variant="contained" size="large" loading={isSubmitting}>
                        {!isEdit ? "ADD" : "Save Changes"}
                      </LoadingButton>
                    </Stack>
                  </Box>
                </Grid>
             
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );

}