import PropTypes from 'prop-types';
import React from 'react';
import { useContext } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../utils/config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//

import pn from '../../../images/pickupLogo-1.png';
import NavbarDocs from '../navbar/NavbarDocs';
import { MainContext } from 'src/contexts/context';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: '0 0 2px 2px #c4cdd5',
  background: "white",
  height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  zIndex: 10000,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: '',
    ...(isCollapse && {
      width: '100VW',
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100VW',
      height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

const useCurrentCallback = (callback) =>
{
  const reference = React.useRef();
  reference.current = callback;
  return (...args) =>
  {
    return reference.current?.(...args);
  };
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false })
{
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  //  const { type }= useContext(MainContext);
  const obj = window.localStorage.getItem("obj");
  const [dt, setDt] = React.useState(new Date());
  const isDesktop = useResponsive('up', 'xs');
  React.useEffect(() =>
  {
    let timer = setInterval(() => setDt(new Date()), 1000);
    return function cleanup()
    {
      clearInterval(timer);
    };

  });

  return (
    <RootStyle className="topHeader" isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <img src={pn} alt="jhdj" style={{ height: "45px" }} />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="right" spacing={{ xs: 0.5, sm: 1.5 }} sx={{ display: "flex", flexDirection: 'column' }}>

          <Typography align='right' sx={{ color: 'black', fontSize: "16px", fontWeight: 700 }}>{dt.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}</Typography>
          {obj !== null ? <></> : <Typography sx={{ color: 'black', fontSize: "16px", fontWeight: 300 }}> BHUBANESWAR </Typography>}
        </Stack>
        <NavbarDocs />
      </Toolbar>
    </RootStyle>
  );
}
