import axios from "axios";
axios.defaults.baseURL = `${process.env.REACT_APP_BASEURL}`;

const instance = axios.create({
  baseURL: axios.defaults.baseURL,
});
instance.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("pickup-ninja-token")}`;
export default instance;
