import {useContext} from 'react'
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
// @mui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedTextFieldProps,
  Tab,
  Tabs,
  Box,
  IconButton, MenuItem
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import axios from '../../utils/axiosconfig';
import MenuPopover from 'src/components/MenuPopover';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user/list';

// sections
import { groupListHead, groupListToolbar, UserMoreMenu } from '../../sections/@dashboard/user/list';
import { MainContext } from 'src/contexts/context';
// import GroupList from './GroupList';


// tab start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// tab end



function Innerpage(dataaa) {
  const { children, c_Data, index, ...other } = dataaa;

  return (
    <div
      role="Innerpage"
      hidden={c_Data !== index}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      {...other}
    >
      {c_Data === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function abc(index) {
  return {
    id: `main-tab-${index}`,
    'aria-controls': `main-tabpanel-${index}`,
  };
}


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Group Name', alignRight: false },
  { id: 'company', label: 'Notification', alignRight: false },
  { id: 'role', label: 'Time', alignRight: false },
  { id: 'role', label: 'Status', alignRight: true },

  { id: 'status', label: 'Active', alignRight: true },
  { id: 'Action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function Campaigns() {
  const theme = useTheme();
  const { themeStretch } = useSettings();

  const [campaignList, setCampaignList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [value, setValue] = useState(0);
  const [deleted, setDeleted] = useState('');
  const [type, setType] = useState('all');
  const [limit, setLimit] = useState(0);
  const [filterData, setFilterData] = useState();
  const [filterValue, setFilterValue] = useState();
  const [searchValue, setSearchValue] = useState();
  const {managerId} =useContext(MainContext)
  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#e65100',
  //     },
  //   },
  // });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [c_Data, setc_Data] = useState(0);

  const pageChange = (event, newc_Data) => {
    setc_Data(newc_Data);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = campaignList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (userId) => {
    const deleteUser = campaignList.filter((user) => user.id !== userId);
    setSelected([]);
    setCampaignList(deleteUser);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = campaignList.filter((user) => !selected.includes(user.name));
    setSelected([]);
    setCampaignList(deleteUsers);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - campaignList.length) : 0;

  const filteredUsers = applySortFilter(campaignList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && Boolean(filterName);


  // useEffect(() => {
  //   getCampaign();
  //   if (deleted !== '') {
  //     enqueueSnackbar("Group deleted successfully");
  //   }
  // }, [deleted]);

  const deleteCampaign = (id) => {
    axios.delete(`campaigns/delete/${id}`).then((res) => {
      console.log(res.data);
      setDeleted(res.data.result);
    }).catch((err) => {
      console.log("error", err)
    })
  }
  useEffect(() => {
        getCampaign();
  }, [type,searchValue, filterValue,deleted]);
  
  const getCampaign = async () => {
    const payload = {};
    payload.managerId=managerId;
    payload.type = type;
    payload.filter = filterValue; 
    payload.search = searchValue;
    payload.page = page;
    payload.limit = limit;
    try{
      const res = await axios.post(`/campaigns/get/all`,payload);
      setCampaignList(res.data.result);
      setFilterData(res.data.filters);
    }
    catch(e){
      console.log(e);
    }
   
  }


  const Convert = (dt) => {
    // const dd = new Date(dt).getDate() + "/" + new Date(dt).getMonth() + "/" + new Date(dt).getFullYear();
    if(!dt) return ""
    const date = new Date(dt)
    const ddate = moment(date).format(`D MMMM ,YYYY`)
    return ddate;
  }


  return (
    <ThemeProvider>
      <Page title="Campaigns">

        <Box sx={{ width: "100%" }}>

        <Button className='createBtn'
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.campaigns.newCampaign}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                style={{ background: '#E04E2C' }}
              >
                New Campaign
              </Button>

          <Card sx={{borderRadius:'0'}}>

            <Innerpage value={c_Data} index={0}>

            </Innerpage>
            <Innerpage value={c_Data} index={1}>
              Item Two
            </Innerpage>
            <Innerpage value={c_Data} index={2}>
              Item Three
            </Innerpage>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDeleteUsers={() => handleDeleteMultiUser(selected)}
              setFilterValue={setFilterValue}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              filterData={filterData}

            />


            <TableContainer sx={{ minWidth: 800, height: 'calc(100vh - 375px)' }}>
              <Scrollbar>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={campaignList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                      const isItemSelected = selected.indexOf(row?.id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row?.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Avatar alt={row.name} src={row.avatar} sx={{ mr: 2 }} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {row?.groupObj?.groupName}
                            </Typography>
                          </TableCell>
                          <TableCell>{row?.notificationObj?.title}</TableCell>
                          <TableCell>{Convert(row?.created)}</TableCell>
                          <TableCell align='right'>{row?.status}</TableCell>
                          <TableCell align="right"><Label color={row?.isActive === false ? "error" : "success"}>{row?.isActive ? 'Yes' : 'No'}</Label></TableCell>


                          <TableCell align="right">
                            <ThreedotMenu row={row} deleteCampaign={deleteCampaign} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {campaignList?.length===0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={15} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={searchValue} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={campaignList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, page) => setPage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>

        </Box>
      </Page>
    </ThemeProvider>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



export function ThreedotMenu({ row, deleteCampaign }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem onClick={() => deleteCampaign(row?.id)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} style={{ marginRight: "5px" }} />
          Delete
        </MenuItem>

        <MenuItem component={RouterLink} to={`${PATH_DASHBOARD.group.editById}/${row?.id}`}>
          <Iconify icon={'eva:edit-fill'} style={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  )
}